import React from 'react'
import Model from './Model/headdresscase_HM_1909.glb'
import Icon from './Model/headdresscase_HM_1909.png'
import PNG from './Model/headdresscase_HM_1909.webp'

import StraightupHeaddress from './Model/content/StraightupHeaddress.jpg'

export var headdresscase_HM_1909 = {
  id: 'headdresscase_HM_1909',
  name: 'Headdress Case',
  translation: 'Skinítsimaan',
  png: PNG,
  meta: {
    date: 'Before 1909',
    origin: 'Amskapipiikani',
    tribe: 'Amskapipiikani',
    materials: [ 'Sinew', 'Paint', 'Rawhide' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The Horniman Museum',
      ref: 'https://www.horniman.ac.uk/object/25.52',
    },
    dimensions: {
      length: '556mm',
      width: '',
      height: '155mm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Rawhide',
        category: 'materials',
        dataPosition:
          '-0.41037354316462904m 1.201971572432369m -0.3680854707545682m',
        dataNormal:
          '-0.7079575026687956m -0.49287783825671555m -0.5058335803111033m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Parfleche, or rawhide, was prepared by cleaning and dehairing
                  the skin. It was then stretched and dried in the sun. This was
                  done by staking it above the ground, and scraping it to an
                  even thickness. Prickly pear juice was applied for protection
                  before the hide was painted. The hide needed to be dampened
                  with water before you started this process. This process
                  created a stiff but durable leather that was waterproof.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Paint',
        category: 'materials',
        dataPosition:
          '0.41846549515071946m 1.3897909056856441m -0.06435132962706658m',
        dataNormal:
          '0.9276399826417289m 0.3730123226685195m 0.018597573548433426m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The substances used to design parfleche cases were similar to
                  paints that were available in various locations around the
                  world during the time. Blackfoot people used plants, fungi,
                  and clay to create dyes (or paint-like substances), which were
                  used to add color to the items that Blackfoot people were
                  creating. There are individuals that still know how to create
                  dyes or paint out of the items that are naturally available
                  within, and outside, of the Blackfoot Confederacy. Today
                  commercial paints and dyes are used to design some items.
                  Commercial dyes and paints have made it easier to add color
                  over time and require less physical work and time.
                </p>
                <p>
                  In Blackfoot, paint may be referred to in a few different
                  ways: <br />
                  <strong>isttsikoksspainni</strong> (Paint with a sticky
                  substance)
                  <br />
                  <strong>o’kspanohkio’taki</strong> (Paint for utilitarian
                  purposes)
                  <br />
                  <strong>i’kokatoo</strong> (Paint designs on a Tipi)
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Tanned Hide',
        category: 'materials',
        dataPosition:
          '-0.6038180743411644m 2.3762572155664774m -0.042403586880779m',
        dataNormal:
          '-0.4512113066255204m 0.6997465992357241m 0.5538619445595894m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Hide is harvested from animals, which were mostly hunted for
                  food. Blackfoot people would try to use all that the animal
                  had to offer including the hides and bones. This was done out
                  of respect and necessity, as they knew that the animal
                  sacrificed itself to provide for them.
                  <br />
                  <br />
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather is a lengthy tedious process that can span over a
                  length of time. This can range from days to weeks. Tanned
                  hides were central to survival, used for shelter, clothes,
                  bags, and more.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Sinew',
        category: 'materials',
        dataPosition:
          '-0.16081794563922222m 1.4251509739220194m 0.4368184230524783m',
        dataNormal:
          '0.010582358120493383m 0.6212682739360088m 0.7835264804058519m',
        content: [
          {
            type: 'text',
            title: 'Neck Sinew',
            titleBlackfoot: 'Paakahtaan',
            description: (
              <div>
                <p>
                  Sinew is the tough fibrous tissues that unite muscle to bone
                  or bone to bone on the animal. This was often harvested from
                  animals that existed within the region such as deer and
                  buffalo and used to create items used for both ceremony and
                  everyday use. Sinew has to be stored properly to prevent
                  rotting.
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This parfleche container was made to carry a Straight-up
                Headdress. The Straight-up Headdress is specific to the
                Blackfoot Confederacy and worn by both male and female members.
                The Blackfoot headdress predates colonial contact and the
                parfleche bags and containers were primarily used to carry
                personal items before settlers first stepped onto The Americas.
                It is important to remember that Parfleche bags and containers
                are still used within the Blackfoot Confederacy to carry
                ceremonial items and objects for those who actively engage in
                knowledge renewal and transmission practices.
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Straightup Headdress',
          url: StraightupHeaddress,
          description: (
            <div>
              <p>
                Yellow Kidney wearing a Straight-up Headdress with Little Plume
                and Chief Crow. Photo from the{' '}
                <a href="https://collections.maa.cam.ac.uk/photographs/318189">
                  MAA photo archive
                </a>
                .
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The owner of this parfleche case is unknown. The parfleche case
                would have been made to carry a headdress. The design on the
                case would have been designed for a specific person to signify
                who it belonged to and the significance within their life. Often
                these designs were transferred through immediate kinship
                alliances to signify the connection between individuals.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                It is unclear as to how the case was acquired, other than it was
                likely collected from the Blackfeet in 1909 by John Eric
                Horniman, the grandson of Frederick Horniman (founder of The
                Horniman Museum and Gardens). Eric was part of the expedition
                with A.C. Haddon and Edward Curtis to visit the Blackfeet and
                document their culture. Haddon also acquired many items that
                were donated to the Museum of Archeology and Anthropology at the
                University of Cambridge.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                Eric Horniman donated items he collected from his visit to the
                Blackfeet to his family's museum in 1913. This headdress case is
                now kept in storage at The Horniman Museum and Gardens in Forest
                Hill, London, England.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This particular Headdress Case is made from a single piece of
                rawhide with tanned hide used to join the two edges together to
                form a cylinder case. Where the two edges meet, fringed leather
                was used to indicate the type of headdress that was in the case.
                The fringes indicated that it contained ceremonial or sacred
                medicine objects. A rough circular rawhide disc was attached to
                the bottom and secured with tanned leather which was kept long
                to indicate the bottom of the case. The top of the case also has
                a circular disk which was made to be removed, so that the
                individual could place the headdress inside, but also allowed
                for access to the headdress when needed. The outside of the case
                was painted with a design using plant based paints which was
                common practice during the time.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Design',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Designs were consistent across nations, as they preserve and
                allow for knowledge transmission to occur between individuals
                and generations. This process required the designs, symbolisms,
                meanings and techniques to be transferred from one individual to
                the next, with Elders guiding the process of preserving,
                practicing, and engaging in oral history and knowledge
                practices, as well as the physical creation of the case. Common
                design elements consisted of three main elements- a rhombus
                between two triangles attaching at their points.
              </p>
            </div>
          ),
        },
      ],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Sinew', 'Paint', 'Hide' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [ 'Amskapipiikani' ],
    },
    {
      title: 'Museum Collection',
      options: [ 'The Horniman Museum' ],
    },
    {
      title: 'Theme',
      options: [ 'Ceremonial', 'Cases' ],
    },
  ],
}
