import React from "react";
import Model from "./Model/storyrobe_BM_1869.glb";
import Icon from "./Model/storyrobe_BM_1869.png";
import PNG from "./Model/storyrobe_BM_1869.webp";


export var storyrobe_BM_1869 = {
  id: "storyrobe_BM_1869",
  name: "Story Robe",
  translation: "Iitsiiniiksi’naatsin",
  png: PNG,
  meta: {
    date: "Before 1856",
    origin: "",
    tribe: "",
    materials:["Hide", "Paint"],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The British Museum',
      ref: 'https://www.britishmuseum.org/collection/object/E_Am-917',
    },
    dimensions: {
      length: "",
      width: "168cm",
      height: "224cm",
    },
  },
  mapMarker: {
    lat:null,
    lng:null,
    icon: Icon,
  },
  Model: {
    rti: "",
    mdl: Model,
    icon: Icon,
    hotspots: [
            {
        name: "Buffalo Hide",
        category: "materials",
        dataPosition: "1.2754728854818094m 5.89538045828758m -2.1817767640996957m",
        dataNormal: "0.20380070599886776m 0.08882339356581984m 0.9749747058205227m",
        content: [
        {
          type: "text",
          description: (
            <div><p>
            Buffalo hide was an important resource before the buffalo disappeared in the 1870s due to colonization. Iinii (buffalo) were mostly hunted for food and Blackfoot people would try to use all that the animal had to offer. This was done out of respect, as they knew that the animal sacrificed itself.  
            </p><p>
            Harvesting hide and applying tanning methods to create buttery soft leather is a lengthy tedious process that can span over a length of time. This can range from days to weeks. Tanned hides were central to survival, used for shelter, clothes, bags, and more.
            </p>
            <p>
            In Blackfoot, hide may be referred to in a few different ways: <br/>
            <strong>Paanssin</strong> (smoked or tanned hide)<br/>
            <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on both sides) <br/>
            <strong>Motokis</strong> (Hide or skin - before its been processed)<br/>
            </p>
            </div>
          ),
        },
        ],
      },
      {
        name: "Paint",
        category: "materials",
        dataPosition: "-1.1307573086454084m 4.7795957467691625m -1.6386541631526341m",
        dataNormal: "0.09435230135233978m 0.259063544772435m 0.9612407206311321m",
        content: [
        {
          type: "text",
          description: (
            <div><p>The paint used to depict stories, events, or history were harvested from the Earth. There were many plants, fungi, and clay that were used to create dyes, which were used to add color to the items that Blackfoot people were creating. Some individuals still know how to create dyes or paint out of the items that are naturally available within and outside of the Blackfoot Confederacy. Commercial dyes and paints have made it easier to add color over time and require less physical work and time. 
            </p>
            <p>In Blackfoot, paint may be referred to in a few different ways: <br/>
            <strong>isttsikoksspainni</strong> (Paint with a sticky substance)<br/>
            <strong>o’kspanohkio’taki</strong> (Paint for utilitarian purposes)<br/>
            <strong>i’kokatoo</strong> (Paint designs on a Tipi)
            </p></div>
          ),
        },
        ],
      },
    ],
  },
  description: [
    {
      title: "Intro",
      content: [
        {
          type: "text",
          description: (
            <div><p>
               Story robes were often used to depict important events within a tribe or individual’s life. Some would depict the winter counts while others were created to illustrate the story of a warrior's journey including battles, coups, accomplishments.These pictographs are public records of personal achievement. They were often recorded through placing images on animal hides or buffalo robes. Sometimes these recordings were also placed on the tipi coverings or linings, the shield belonging to a warrior, or within the ledger books that were provided by Indian agents. 
            </p></div>
          ),
        },
        
      ],
    },
    {
      title: "History",
      content: [
        {
          type: "text",
          title: "Origins ",
          description: (
            <div><p>
               The maker and wearer of this robe was not recorded. Story robes are passed from one person to the next as a form of knowledge renewal within families and societies. Oral history was practiced and transferred with the robe to those who were chosen to care for the knowledge. Knowledge transmission occurred throughout this process and ensured that the next generation would know Blackfoot history as their ancestors experienced it. </p>
               <p>The knowledge associated with the story robes were maintained as long as there was someone to care for the knowledge, and understood the significance of each particular symbol used, that could receive the item through a transfer. 
            </p></div>
          ),
        },
        
        
        {
          type: "text",
          title: "Acquisition",
          description: (
            <div><p>
               The robe was most likely bought or ‘collected’ from Blackfoot territory by the banker and textile manufacturer Henry Christy on his trip through North America in 1856. We have not yet found details of that journey. Christy would then have kept it at his home as part of his extensive collection on Victoria Street in South West London.
            </p></div>
          ),
        },
        
        {
          type: "text",
          title: "Where is it now?",
          description: (
            <div><p>
               After his death in 1865, Christy’s substantial collection was bequeathed to the British Museum. This robe is now in storage in The British Museum storage in London, England. It has been on display in a number of exhibitions at The British Museum and the Bundeskunsthalle in Bonn, Germany.
            </p></div>
          ),
        },
      ],
    },
    {
      title: "Materials",
      content: [
        {
          type: "text",
          description: (
            <div><p>
               Story robes were usually recorded on the hides of animals, particularly Bison hides. The drawings were created utilizing natural inks and colors derived from harvesting materials such as plants, rocks, animal fat, and creating a concoction of the materials which were then used to paint the images onto the hide. 
            </p></div>
          ),
        },
      ],
    },
  ],
  categories: [
    {
      title: "Materials",
      options: ["Hide", "Paint"],
    },
    {
      title: "Blackfoot Tribe",
      options: [""],
    },
    {
      title: "Museum Collection",
      options: ["The British Museum"],
    },
    {
      title: "Theme",
      options: ["Pictographs"],
    },
  ],
};