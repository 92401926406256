import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import PropTypes from 'prop-types'
import TranslationButton from './TranslationButton'
import style from './style/MainMenu.module.css'
import _ from 'lodash'
import mootookakiossinPronounciation from "./Audio/mootookakiossin.mp3";
export default class MainMenu extends Component
{
  state = {
    panelState: 'open',
    init: true,
    activeLink: 'Explore',
    left: 0,
    temp: false,
  }

  componentDidMount()
  {
    // var str = window.location.href
    // var n = str.lastIndexOf('/')
    // var result = str.substring(n + 1)
    // if (result.includes('About')) {
    //   this.setActiveLink('About')
    // } else if (result.includes('intro')) {
    //   this.setActiveLink('Intro')
    // } else {
    //   this.setActiveLink('Explore')
    // }

    // if (result.includes('about')) {
    //   this.setActiveLink('About')
    // } else if (result.includes('explore')) {
    //   this.setActiveLink('Explore')
    // } else {
    //   this.setActiveLink('Intro')
    // }

    this.setActiveLink(this.props.cr)
    // if (window.matchMedia("(max-width: 675)")) {
    //   this.setState({ panelState: "open" });
    // }

    window.addEventListener('resize', _.debounce(this.resize, 50))
    // this.setState({ panelState: 'open' })
  }

  componentDidUpdate()
  {
    // var str = window.location.href
    // // var n = str.indexOf('/')
    // // var result = str.substring(n + 1)
    // if (str.includes('explore')) {
    //   this.setActiveLink('Explore')
    // } else if (str.includes('about')) {
    //   this.setActiveLink('About')
    //   // this.setState({ temp: true })
    // } else {
    //   this.setActiveLink('Intro')
    // }
    this.setActiveLink(this.props.cr)
  }

  resize = () =>
  {
    // var str = window.location.href;
    // var n = str.lastIndexOf("/");
    // var result = str.substring(n + 1);
    // if (result.includes("About")) {
    //   this.setActiveLink("About");
    // } else if (result.includes("Intro")) {
    //   this.setActiveLink("About");
    // } else {
    //   this.setActiveLink("Explore");
    // }
    if (this.state.left === 0)
    {
      this.setState({ left: 1 })
    } else
    {
      this.setState({ left: 0 })
    }
  }

  togglePanel = () =>
  {
    if (this.state.panelState === 'close')
    {
      this.setState({ panelState: 'open' })
    } else if (this.state.panelState === 'open')
    {
      this.setState({ panelState: 'close' })
    }
  }

  getLeft = (i, right) =>
  {
    if (i === 0)
    {
      return `${right}px`
    } else if (i === 1)
    {
      if (this.state.activeLink === this.props.destinations[ 0 ].title)
      {
        return `${i * 100 + right}px`
      }
      return '100%'
    } else
    {
      if (this.state.activeLink === this.props.destinations[ i ].title)
      {
        return '100%'
      } else
      {
        for (var x = 0; x < this.props.destinations.length; x++)
        {
          if (
            (this.props.destinations[ x ].title ===
              this.props.destinations[ i ].title) ===
            this.state.activeLink
          )
          {
            if (i < x)
            {
              return '100%'
            }
          }
        }
        return `${(i - (i - 2) - 1) * 100 + right}px`
        // return `${right + 100}px`;
      }
    }
  }

  // /******************************************************
  //  * Width Transition
  //  */
  // getNavStyle = (i, title) => {
  //   var el = document.getElementById('MainMenuButton')
  //   if (el) {
  //     var rect = el.getBoundingClientRect()
  //     if (this.state.panelState === 'open') {
  //       return { left: `${i * 100 + rect.right}px`, width: '100px' }
  //     } else if (this.state.panelState === 'close') {
  //       if (this.state.activeLink === title) {
  //         return {
  //           left: rect.right,
  //           zIndex: '9999999',
  //           width: '100px',
  //         }
  //       } else {
  //         // left: `${(i - 1) * 100 + rect.right}px`,
  //         return {
  //           left: this.getLeft(i, rect.right),
  //           // transitionDelay: `${i * 0.5}s`,
  //           zIndex: '0',
  //           width: '0',
  //         }
  //       }
  //     }
  //   } else {
  //     if (this.state.activeLink === title) {
  //       return { left: '100%', zIndex: '99999999', width: '100px' }
  //     } else {
  //       return { left: '100%', zIndex: '0', width: '0' }
  //     }
  //   }
  // }

  /******************************************************
   * Width Transition
   */
  getNavStyle = (i, title) =>
  {
    var el = document.getElementById('MainMenuButton')
    if (el)
    {
      var rect = el.getBoundingClientRect()
      if (this.state.panelState === 'open')
      {
        return { left: `${i * 100 + rect.right}px`, width: '100px' }
      } else if (this.state.panelState === 'close')
      {
        if (this.state.activeLink === title)
        {
          return {
            left: rect.right,
            zIndex: '9999999',
            width: '100px',
            opcaity: '0',
          }
        } else
        {
          // left: `${(i - 1) * 100 + rect.right}px`,
          return {
            //left: this.getLeft(i, rect.right),
            left: rect.right,
            // transitionDelay: `${i * 0.5}s`,
            zIndex: '0',
            width: '100px',
            opacity: '0',
          }
        }
      }
    } else
    {
      if (this.state.activeLink === title)
      {
        return { left: '100%', zIndex: '99999999', width: '100px' }
      } else
      {
        return { left: '100%', zIndex: '0', width: '100px', opacity: '0' }
      }
    }
  }

  getChevronStyle = () =>
  {
    if (this.state.panelState === 'initial')
    {
      return [ style.chevron, style.chevronInactive ].join(' ')
    }
    if (this.state.panelState === 'open')
    {
      return [ style.chevron, style.chevronActive ].join(' ')
    } else if (this.state.panelState === 'close')
    {
      return [ style.chevron, style.chevronInactive ].join(' ')
    }
  }

  setActiveLink = (title) =>
  {
    if (title !== this.state.activeLink)
    {
      // if (window.matchMedia('(max-width: 675)')) {
      //   this.setState({ activeLink: title, panelState: 'close' })
      // } else {
      if (this.state.init === true)
      {
        this.setState({ activeLink: title })
      } else
      {
        this.setState({ activeLink: title, panelState: 'close' })
      }

      // }
    }
  }

  setNavClass = (title) =>
  {
    if (this.state.activeLink === title)
    {
      return [ style.navItem, style.navActive ].join(' ')
    } else
    {
      return style.navItem
    }
  }

  /**********************************************************
   * Temporary menu render that returns Explore route as an
   * href to facilitate reloading. This will fix the
   * model-viewer memory leak for the time being.
   **********************************************************/
  renderMainMenu = () =>
  {
    return (
      <div className={style.menuContainer}>
        {/* <div className={style.inner}> */}
        <MediaQuery minWidth={1176}>
          <button
            className={style.openbtn}
            id="MainMenuButton"
          >
            <div className={style.titleAudioGrouping}>
              <h1 onClick={this.togglePanel}>Mootookakio’ssin</h1>
              <TranslationButton
                audio={mootookakiossinPronounciation}
                size={25}
                id={"mootookakiossinTranslationButton"}
                visible={false}
              // title={"Mootookakio'ssin Pronounciation"}
              />
            </div>

            <span onClick={this.togglePanel} className={this.getChevronStyle()}></span>
            <h2 className={style.subtitle}>distant awareness</h2>
          </button>
          {this.props.destinations.map((object, i) =>
            object.title === 'Explore' ? (
              //
              <a href="/explore" key={i}>
                <div
                  className={style.navItem}
                  style={this.getNavStyle(i, object.title)}
                  onClick={() =>
                  {
                    this.setActiveLink(object.title)
                    this.setState({ init: false })
                  }}
                  id={`MainMenu_${object.title}`}
                >
                  <div
                    className={style.navIcon}
                    style={{
                      maskImage: `url(${object.icon})`,
                      WebkitMaskImage: `url(${object.icon})`,
                      maskSize: '50%',
                      WebkitMaskSize: '50%',
                    }}
                  ></div>
                  <h2>{object.title}</h2>
                </div>
                {/* // </NavLink> */}
              </a>
            ) : (
              <NavLink
                to={
                  object.route === '/about' ? `${object.route}/#` : object.route
                }
                key={i}
              >
                <div
                  className={style.navItem}
                  style={this.getNavStyle(i, object.title)}
                  onClick={() =>
                  {
                    this.setActiveLink(object.title)
                    this.setState({ init: false })
                  }}
                  id={`MainMenu_${object.title}`}
                >
                  <div
                    className={style.navIcon}
                    style={{
                      maskImage: `url(${object.icon})`,
                      WebkitMaskImage: `url(${object.icon})`,
                    }}
                  ></div>
                  <h2>{object.title}</h2>
                </div>
              </NavLink>
            ),
          )}
        </MediaQuery>
        <MediaQuery maxWidth={1175}>
          <div className={style.titleAudioGrouping}>
            <button className={style.openbtn} id="MainMenuButton">
              <h1>Mootookakio’ssin</h1>
              <h2>distant awareness</h2>
            </button>
            <TranslationButton
              audio={mootookakiossinPronounciation}
              size={25}
              id={"mootookakiossinTranslationButton"}
              visible={false}
              direction={"right"}
            // title={"Mootookakio'ssin Pronounciation"}
            />
          </div>
          <div className={style.linkBox}>
            {this.props.destinations.map((object, i) =>
              object.title === 'Explore' ? (
                <a href="/explore" key={i}>
                  <div
                    className={style.navItem}
                    id={`MainMenu_${object.title}`}
                  >
                    <h2
                      style={
                        object.title === this.state.activeLink
                          ? { opacity: '1', fontWeight: 'bold' }
                          : { opacity: '0.45' }
                      }
                    >
                      {object.title}
                    </h2>
                  </div>
                </a>
              ) : (
                <NavLink
                  // to={object.route}
                  to={
                    object.route === 'about'
                      ? `${object.route}/#`
                      : object.route
                  }
                  key={i}
                  onClick={() => this.setActiveLink(object.title)}
                >
                  <div
                    className={style.navItem}
                    id={`MainMenu_${object.title}`}
                  >
                    <h2
                      style={
                        object.title === this.state.activeLink
                          ? { opacity: '1', fontWeight: 'bold' }
                          : { opacity: '0.45' }
                      }
                    >
                      {object.title}
                    </h2>
                  </div>
                </NavLink>
              ),
            )}
          </div>
        </MediaQuery>
      </div>
    )
  }

  render()
  {
    return this.renderMainMenu()
  }
}

MainMenu.propTypes = {
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.any.isRequired,
    }),
  ),
}
