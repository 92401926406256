import React from 'react'
import Model from './Model/belt_BM_1945.glb'
import Icon from './Model/belt_BM_1945.png'
import PNG from './Model/belt_BM_1945.webp'

import SmokedTannedHideAudio from './Model/content/t-rex-roar.mp3'
export var belt_BM_1945 = {
  // unique id. I dont specifically think this is neccesary at the moment, but may prove useful later in development.
  // If not, we can remove it.
  id: 'belt_BM_1945',
  //Name of the Artifact.
  name: 'Beaded Belt',
  translation: 'Iipsattsis',
  //mdl: the actual 3D object file (.glb or .gltf) is imported at the top of the
  //file, and associated to the model here.
  // mdl: Sheath,
  png: PNG,
  // rti: false,
  meta: {
    date: 'before 1945',
    origin: '',
    tribe: '',
    location: {
      lat: null,
      lng: null,
    },
    museum: 'The British Museum',
    materials: [ 'Hide, Beads' ],
    dimensions: {
      //diameter: '28cm'
      height: '9cm',
    },
    type: 'Clothing',
  },
  Model: {
    mdl: Model,
    icon: Icon,
    // rti: "/RTI/",
    hotspots: [
      {
        name: 'Beads',
        category: 'materials',
        dataPosition:
          '1.9214564682318227m 0.06259204090984023m 1.2190850611168855m',
        dataNormal:
          '0.910924882389876m -0.027507934583514655m 0.41165419004054793m',
        content: [
          {
            type: 'text',
            title: 'Beads',
            titleBlackfoot: 'Áótooksiinaattsi',
            description: (
              <div>
                <p>
                  Colonial expansion brought new materials with the settlers.
                  Among these items were beads. Beads were quickly recognized as
                  being valuable and were highly sought after by Indigenous
                  people when trade routes were established (Gray, 2017). The
                  seed bead was introduced in the 18th century and was smaller
                  than the pony beads, which arrived in the 16th century(as
                  cited in Gray, 2017). The beads used to decorate these
                  moccasins appear to be pony beads (round) and tile beads
                  (straight edges).
                  <br />
                  <br />
                  More than decoration, beadwork is an expression of the
                  beader’s identity, spiritual beliefs, and connections. Designs
                  can be passed down through families and have different
                  meanings and teachings (Belcourt).
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  Belcourt, Christi. Beadwork: First Peoples' Beading History
                  and Techniques. Owen Sound, Ont: Ningwakwe Learning Press,
                  2010.
                  <br />
                  <br />
                  Gray, M. (2017). Beads: Symbols of Indigenous Cultural
                  Resilience and Value (Unpublished Master’s Thesis). University
                  of Toronto, Toronto, Ontario.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Hide',
        category: 'materials',
        dataPosition:
          '-0.5637482183976973m 0.05440556207606815m -2.2302031725138924m',
        dataNormal:
          '0.35529229982495597m 0.03468238112841933m 0.9341116175941485m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Hide is harvested from animals, which were mostly hunted for
                  food. Blackfoot people would try to use all that the animal
                  had to offer. This was done out of respect, as they knew that
                  the animal sacrificed itself.
                  <br />
                  <br />
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather is a lengthy tedious process that can span over a
                  length of time. This can range from days to weeks. Tanned
                  hides were central to survival, used for shelter, clothes,
                  bags, and more.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Morning Star',
        category: 'design',
        dataPosition:
          '-2.181279130985566m 0.009858400965710515m -0.5685213954023018m',
        dataNormal:
          '-0.9577016677045835m -0.0054364501998226435m -0.287711592893099m',
        content: [
          {
            type: 'text',
            title: 'Morning Star',
            titleBlackfoot: 'Ipiso waahsa',
            description: (
              <div>
                <p>
                  Ipiso waahsa (Morning Star) is the son of Natosi(Sun) and
                  Kokomi kisomm (Moon), the Spomi-tapi-ksi (The Sky Beings) who
                  give Blackfoot people important ceremonies and guidance.
                </p>
              </div>
            ),
          },
          {
            type: 'text',
            title: 'Stories',
            description: (
              <div>
                <p>
                  <a
                    href="https://www.blackfootdigitallibrary.com/digital/collection/bdl/id/226 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Star Woman as told by Alfreda Delaney
                  </a>
                  <br />A version of the Sooa'tsaakii story as told by Alfreda
                  Delaney and dramatized by Norma Russell, Jenny Fox, Gordon
                  Morning Bird, and Faye Heavy Shield.
                  <br />
                  <br />
                  <a
                    href="http://www.virtualmuseum.ca/edu/ViewLoitDa.do;jsessionid=EAF551A7FAFC4E248DC36B0D0072A2C7?method=preview&lang=EN&id=5220"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The North Star and Morning Star{' '}
                  </a>
                  <br />
                  Another version of the Sooa’tsaakii story as told by Mrs. Wolf
                  Plume, Amsskaapipikani in 1911.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Mistaken Morning Star',
        category: 'design',
        dataPosition:
          '0.7485496152643142m 0.06180936089741363m 2.1524352711943724m',
        dataNormal:
          '0.1120205274107047m -0.01618756205158677m 0.9935740356276695m',
        content: [
          {
            type: 'text',
            title: 'Mistaken Morning Star',
            titleBlackfoot: "pa'papiso waahsa",
            description: (
              <div>
                <p>
                  When there are two crosses, one is the morning star and the
                  other is the Mistaken Morning Star.
                </p>
              </div>
            ),
          },
          {
            type: 'text',
            title: 'Stories',
            description: (
              <div>
                <p>
                  <a
                    href="https://www.indigenouspeople.net/sweatlod.htm "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Origin of the Sweat Lodge
                  </a>
                  <br />
                  On indigenouspeople.net compiled by Glenn Welker.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Whirlpool',
        category: 'design',
        dataPosition:
          '-1.6003692902125326m -0.07060180809885422m 1.5273144963888017m',
        dataNormal:
          '-0.6484851163626087m -0.05995333002392962m 0.7588627359906506m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>A whirlpool is depicted with the double diamond shape.</p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  //description array. An array of js objects, used to store the artifact descriptions and
  //informational 'content' in categories pertaining to the 'title'.
  //Title and content are necessary keys, but the values can be changed.
  //additional description categories can be added, but must follow this format and cannot be added to the start.
  //First element must be the initial description at description[0]
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                A leather belt decorated with seed beads tightly sewn in a
                geometric pattern. While the exact origins of the belt are
                unknown, museum documentation indicates that it was sent from
                Calgary, Alberta and donated to the British Museum in 1945.
              </p>
              <p>
                Currently, decorated belts are worn as a part of Blackfoot
                regalia during powwows and ceremonies. Other pieces can be tied
                to the belt, like an awl case, pouch, or knife.
              </p>
              <p>
                The Blackfoot translation for belt, 'iipsattsis', is by the
                Piegan Board of Education, published in{' '}
                <a
                  href="https://hsapp.hs.umt.edu/employee-database/index.php/pubtools/serveFile/files/1489/Blackfeet_Terms_of_Material_Culture_--_SH.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blackfeet Vocabulary Terms for Items of Material Culture
                  Clothing
                </a>
                .
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The original maker and wearer of this belt was not recorded.
                Only they would be able to explain the full meaning of the
                belt’s beaded design, which is unique to each beader.{' '}
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                In 1945 the belt was donated to the British Museum where it sat
                in a box of non-accessioned material in the student’s room until
                it was found in 1967. A label near the belt describes an "Indian
                beaded belt, sent from Calgary, Canada, bought at an Indian
                reservation" and donated by Mrs F. C. Godber.{' '}
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                The belt is currently located in The British Museum storage in
                London, England.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
    // {
    //   title: "Stories",
    //   content: [
    //     {
    //       type: "text",
    //       title: "The Woman who married Morning Star",
    //       description: (
    //         <div>
    //           <p>
    //             This story explains how the North Star came into existence, and
    //             also how we received one of our most important ceremonies.
    //           </p>
    //           <p>
    //             <a href="http://www.virtualmuseum.ca/edu/ViewLoitDa.do;jsessionid=EAF551A7FAFC4E248DC36B0D0072A2C7?method=preview&lang=EN&id=5220">
    //               As told by Mrs. Wolf Plume, Amsskaapipikani in 1911
    //             </a>
    //           </p>
    //         </div>
    //       ),
    //     },
    //   ],
    // },
  ],
  //mapMarker object contains information pertaining to the map view. This includes latitude, longitude, and icon (for mapMarkers)
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  categories: [
    {
      title: 'Materials',
      options: [ 'Hide', 'Beads' ],
      // options: ["All"],
    },
    {
      title: 'Blackfoot Tribe',
      // options: ["Piikani", "Amskapipiikani", "Siksika", "Kainai"],
      options: [ '' ],
    },
    {
      title: 'Museum Collection',
      // options: [
      //   "Museum of Archaeology and Anthropology",
      //   "The British Museum",
      //   "The Horniman Museum",
      // ],
      options: [ 'The British Museum' ],
    },
    {
      title: 'Theme',
      // options: [
      //   "Clothing",
      //   "Child Rearing",
      //   "Tools",
      //   "Pipes",
      //   "Cases",
      //   "Paint",
      // ],
      options: [ 'Clothing' ],
    },
  ],
}
