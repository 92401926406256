import React from 'react'
import Model from './Model/pipebowl_BM_1882.glb'
import Icon from './Model/pipebowl_BM_1882.png'
import PNG from './Model/pipebowl_BM_1882.webp'

export var pipebowl_BM_1882 = {
  id: 'pipebowl_BM_1882',
  name: 'Pipe',
  translation: 'Ahkoyinimaan',
  png: PNG,
  meta: {
    date: 'Before 1882',
    origin: '',
    tribe: '',
    materials: ['Stone'],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The British Museum',
      ref: 'https://www.britishmuseum.org/collection/object/E_Am-Db-13',
    },
    dimensions: {
      length: '3.5cm',
      width: '7.2cm',
      height: '4cm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Blackstone',
        category: 'materials',
        dataPosition:
          '-0.20116806990869796m 3.537519099175567m -12.20517276384574m',
        dataNormal:
          '-0.33353762265012066m 0.34814091820353316m 0.876099626383499m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  This pipe is made of steatite (black pipestone). Different
                  quarries produce different textures of blackstone, which can
                  sometimes look like limestone. Stone is plentiful in Blackfoot
                  territory and is extracted from quarries and mines, including
                  in Lethbridge and near Crowlodge Creek.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Carving',
        category: 'design',
        dataPosition:
          '-1.1657426235241264m 2.424560239131357m -12.543399657959164m',
        dataNormal:
          '-0.6440667217069413m -0.012438794877759531m 0.7648681810427613m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Pipes are one of the few things the Blackfoot carve. The
                  geometric style is commonly used by Blackfoot. Each artist
                  uses their craft to express meaning that is personal to them
                  and the purpose of the pipe.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Hole',
        category: 'design',
        dataPosition:
          '-0.10584836694632394m -2.7140050405074367m -10.315676400579404m',
        dataNormal:
          '-0.21390728927432195m 0.14143092538808183m 0.9665614129165216m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  This hole was carved so that the bowl could be tied to a pipe
                  stem, securing it in place.
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Almost every Blackfoot family has at least one pipe in their
                possession due to their importance in traditional life
                (Blackfoot Crossing). They are an important part of many sacred
                ceremonies that renew kinship relations and alliances of
                Blackfoot people.
              </p>
            </div>
          ),
        },
        {
          type: 'quote',
          quote: (
            <div>
              <p>
                The powers of natural and cosmic alliances are transferred by
                and through those who are carriers of the bundles and pipes.
              </p>
            </div>
          ),
          person: 'Betty Bastien',
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Betty Bastien, Blackfoot Ways of Knowing: The Worldview of the
                Siksikaitsitapi. University of Calgary Press. 2004.
              </p>
              <p>
                <a
                  href="http://www.blackfootcrossing.ca/celebration.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blackfoot Crossing, “Celebration” (page currently under
                  construction).
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The pipe bowl appears to be Blackfoot, however the museum
                records identify it as being found in Marietta, Ohio. It could
                be that the pipe was made by the Blackfoot and traded to another
                tribe before it was acquired by British collectors. Trade was
                common among nations to gain useful materials and other finished
                products.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                This pipe bowl somehow became part of a large collection of
                pipes made by William Bragge, a British civil engineer who
                travelled the world collecting tobacco and smoking pipes. In
                1880 he published a book about tobacco called ‘Bibliotheca
                Nicotiana’, which includes descriptions of over 13,000
                tobacco-related items in his collection.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                The pipe bowl was purchased by The British Museum in 1882. It
                now resides in their storage in London, England.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                <a
                  href="https://www.biodiversitylibrary.org/page/19037303"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  William Bragge. “Prehistoric Pipes - North America”. Page 154.
                  Bibliotheca Nicotiana. 1880.
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: ['Stone'],
    },
    {
      title: 'Blackfoot Tribe',
      options: [''],
    },
    {
      title: 'Museum Collection',
      options: ['The British Museum'],
    },
    {
      title: 'Theme',
      options: ['Ceremonial'],
    },
  ],
}
