import React, { Component, Suspense } from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import { register } from './serviceWorker'
import AppState from './AppState'
// import { lethbridge } from "./Maps/locations";
import MainMenu from './components/MainMenu'
import ThemeContext from './components/ThemeContext'
// import About from "./components/pages/About";
// import My404Page from "./components/pages/My404Page";
// // import MapView from "./components/layout/MapView";
// import RTIViewer from "./components/RTIViewer";
import LightingButton from './components/LightingButton'
import Loading from './components/Loading'
// import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
//Legacy
// import AnalyticsTracker from './components/AnalyticsTracker'
// import _ from "lodash";
// import ModelRing from "./components/ModelRing";
//import StoryPage from "./components/layout/StoryPage";
// import ModelUniverse from "./components/ModelUniverse";
// import ModelExplore from "./components/ModelExplore";
const About = React.lazy(() => import('./components/pages/About'))
const Intro = React.lazy(() => import('./components/pages/Intro'))
const My404Page = React.lazy(() => import('./components/pages/My404Page'))
const RTIViewer = React.lazy(() => import('./components/RTIViewer'))
const StoryPage = React.lazy(() => import('./components/layout/StoryPage'))
// const ModelUniverse = React.lazy(() => import("./components/ModelUniverse"));
const ModelExplore = React.lazy(() => import('./components/ModelExplore'))
const trackingId = 'UA-203945246-1'
const history = createBrowserHistory()
let resizeTimer

// ReactGA.initialize(trackingId)

class App extends Component
{
  /************************************************************
   * App state has been moved to an external file, AppState.js.
   ***********************************************************/
  state = AppState
  componentDidUpdate()
  {
    // this.setState({ defaultTheme: "light" });
    // this.setTheme("light");
    this.setRoute()
  }

  componentWillUnmount()
  {
    window.removeEventListener('load', this.loaded)
    window.removeEventListener('resize', this.resize, false)
    // clearTimeout(resizeTimer)
  }
  componentDidMount()
  {
    this.setRoute()
    if (document.readyState === 'complete')
    {
      var app = document.getElementById('App')
      app.classList.remove('preload')
    } else
    {
      //   // window.onload = this.loaded;
      window.addEventListener('load', this.loaded)
      // window.addEventListener("DOMContentLoaded", this.loaded);
    }
    // window.addEventListener("DOMContentLoaded", this.loaded);
    window.addEventListener('resize', this.resize, false)
    var setTheme = this.state.defaultTheme

    const darkOS = window.matchMedia('(prefers-color-scheme: dark)').matches
    const cachedTheme = localStorage.getItem('theme')
    if (cachedTheme)
    {
      setTheme = JSON.parse(cachedTheme)
    } else if (darkOS)
    {
      setTheme = 'dark'
    } else
    {
      setTheme = this.state.defaultTheme
    }
    // setTheme = "dark";
    //  if (setTheme !== this.state.theme) {
    this.setState({ defaultTheme: setTheme })
    // }
    localStorage.setItem('theme', JSON.stringify(setTheme))
    // this.applyTheme(this.state.themes, setTheme);
    // this.props.setAppTheme(setTheme);

    // ModelViewerElement.modelCacheSize = 1;
  }

  loaded = () =>
  {
    // if (document.readyState === "interactive") {
    setTimeout(function ()
    {
      var app = document.getElementById('App')
      app.classList.remove('preload')
    }, 1000)
  }

  resize = () =>
  {
    var app = document.body
    if (app !== null)
    {
      app.classList.add('preload')
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() =>
      {
        // var x = app.offsetHeight
        // var y = app.children[1].offsetHeight
        app.classList.remove('preload')
      }, 1000)
    }
  }
  /************************************************************
   * Function that takes an obj as an argument,
   * and sets the Apps currentModel to obj.
   ***********************************************************/
  changeName = (obj) =>
  {
    this.setState({ currentModel: obj })
  }

  setTheme = (theme, temp = false) =>
  {
    // if (this.state.defaultTheme !== theme) {
    // if (temp === true) {
    //   this.setState({ tempTheme: true })
    // } else {
    //   this.setTheme({ tempTheme: false })
    // }
    if (theme === 'dark')
    {
      this.setState({ defaultTheme: 'light' })
      if (temp === true)
      {
        this.setState({ tempTheme: true })
      } else
      {
        this.setState({ tempTheme: false })
      }
    } else if (theme === 'light')
    {
      this.setState({ defaultTheme: 'dark' })
      if (temp === true)
      {
        this.setState({ tempTheme: true })
      } else
      {
        this.setState({ tempTheme: false })
      }
    }
    // }
  }
  /***********************************************************
   * Function used to set the currentCategory of
   * the state to a new value. Used for filtering
   * artifact results.
   * Arguments:
   * option: The AppState's currentCategory's
   * subcategory (ex. Materials )
   * category: The value to set option to.
   **********************************************************/
  // setCategory = (option, category) => {
  //   var tempCat
  //   tempCat = []
  //   for (var i = 0; i < this.state.currentCategory.length; i++) {
  //     var x = {}
  //     if (this.state.currentCategory[i].title === category) {
  //       x.title = this.state.currentCategory[i].title
  //       x.options = option
  //       tempCat[i] = x
  //     } else {
  //       x.title = this.state.currentCategory[i].title
  //       x.options = this.state.currentCategory[i].options
  //       tempCat[i] = x
  //     }
  //   }
  //   this.setState({ currentCategory: tempCat })
  // }
  setCategory = (option, category) =>
  {
    var tempCat
    tempCat = []
    for (var x = 0; x < this.state.categories.length; x++)
    {
      tempCat[ x ] = Object.assign({}, this.state.currentCategory[ x ])
    }

    // console.log('setting category')
    for (var i = 0; i < tempCat.length; i++)
    {
      // var x = {}
      if (tempCat[ i ].title === category)
      {
        // x.title = this.state.currentCategory[i].title
        // x.options = option
        // tempCat[i] = x
        if (tempCat[ i ].options.includes(option))
        {
          var ind = tempCat[ i ].options.indexOf(option)
          if (ind > -1)
          {
            tempCat[ i ].options.splice(ind, 1)
          }
          // console.log('SetCategory If: ', tempCat)
        } else
        {
          tempCat[ i ].options.push(option)
          // console.log('SetCategory Else: ', tempCat)
        }
      }
      // else {
      //   x.title = this.state.currentCategory[i].title
      //   x.options = this.state.currentCategory[i].options
      //   tempCat[i] = x
      // }
    }
    this.setState({ currentCategory: tempCat })
  }

  unsetCategory = (option, category) => { }

  /**********************************************************
   * Function resets the currentCategory to "All"
   * for each subCategory.
   * Arguments: none
   *********************************************************/
  resetCat = () =>
  {
    // var tempCat = Object.create(this.state.currentCategory)
    var tempCat
    tempCat = []
    for (var x = 0; x < this.state.categories.length; x++)
    {
      tempCat[ x ] = Object.assign({}, this.state.currentCategory[ x ])
    }
    // tempCat = this.state.currentCategory
    for (var i = 0; i < tempCat.length; i++)
    {
      // var x = {}
      // x.title = this.state.categories[i].title
      tempCat[ i ].options = []
      // tempCat[i] = x
    }
    this.setState({ currentCategory: tempCat })
  }

  setRoute = (r) =>
  {
    if (r !== null && r !== undefined)
    {
      this.setState({ currentRoute: r })
    }
    var str = window.location.href
    // var n = str.indexOf('/')
    // var result = str.substring(n + 1)
    if (str.includes('explore'))
    {
      if (this.state.currentRoute !== 'Explore')
      {
        this.setState({ currentRoute: 'Explore' })
      }
    } else if (str.includes('about'))
    {
      if (this.state.currentRoute !== 'About')
      {
        this.setState({ currentRoute: 'About' })
      }
      // this.setState({ temp: true })
    } else
    {
      if (this.state.currentRoute !== 'Intro')
      {
        this.setState({ currentRoute: 'Intro' })
      }
    }
  }

  // setTheme = () => {
  //   var name = "light";
  //   var themeColours = {
  //     light: {
  //       color: "#2d3e4e",
  //       backgroundColor: "#FFFFFF",
  //     },
  //     dark: {
  //       color: "white",
  //       backgroundColor: "#030303",
  //     },
  //   };

  //   // document.body.style.backgroundColor = themeColours.dark.backgroundColor;
  //   document.body.style.setProperty("--color", themeColours[name].color);
  //   document.body.style.setProperty(
  //     "--background-color",
  //     themeColours[name].backgroundColor
  //   );
  //   document.body.style.setProperty("--h1-color", themeColours[name].color);
  // };
  // componentDidMount() {
  //   this.setTheme();
  // }
  render()
  {
    return (
      <div className={[ 'App', 'preload' ].join(' ')} id="App">
        {/* Router for rendering the app in a given state. All state navigations must be inside of router. */}
        <LightingButton
          // id={'lighting'}
          onClick={() => this.setTheme(this.state.defaultTheme)}
        // text="&#9788;"
        />
        <ThemeContext
          defaultTheme={this.state.defaultTheme}
          themes={this.state.themes}
          setAppTheme={this.setTheme}
          tempTheme={this.state.tempTheme}
        >
          <Router>
            {/* <AnalyticsTracker /> */}
            <MainMenu
              destinations={this.state.destinations}
              cr={this.state.currentRoute}
            />
            {/* Switch ensures that only the first qualifying route is chosen. */}
            {/* <Suspense fallback={<Loading />}> */}
            <Switch>
              {/* About page route. */}
              {/* <Suspense fallback={<div>loading..</div>}> */}
              <Route
                exact
                path="/"
                render={(props) => (
                  <Suspense fallback={<Loading />}>
                    <Intro
                      setTheme={this.setTheme}
                      defaultTheme={this.state.defaultTheme}
                    />
                  </Suspense>
                  // </>
                )}
              />
              <Route exact path="/about">
                <Suspense fallback={<Loading />}>
                  <About setRoute={this.setRoute} />
                </Suspense>
              </Route>
              {/* <Route exact path="/intro" component={Intro} /> */}
              {/* </Suspense> */}
              {/* Route for home page */}
              {/* <MediaQuery minWidth={675}> */}
              <Route
                exact
                path="/explore"
              // render={(props) => (
              >
                <Suspense fallback={<Loading />}>
                  <ModelExplore
                    setRoute={this.setRoute}
                    models={this.state.models}
                    // currentModel={this.state.currentModel}
                    currentModel={this.state.models[ 0 ]}
                    categories={this.state.categories}
                    setCategory={this.setCategory}
                    resetCategory={this.resetCat}
                    currentCategory={this.state.currentCategory}
                    destinations={this.state.destinations}
                  />
                </Suspense>
                {/* // </> */}
                {/* )} */}
                {/* /> */}
              </Route>
              {/* </MediaQuery> */}
              {/* Mapview Route */}
              {/* <Route
                exact
                path="/mapview"
                render={(props) => (
                  <MapView
                    models={this.state.models}
                    categories={this.state.categories}
                    setCategory={this.setCategory}
                    currentCategory={this.state.currentCategory}
                    center={lethbridge}
                    currentModel={this.state.currentModel}
                    resetCategory={this.resetCat}
                    view={"mapview"}
                  />
                )}
              /> */}
              {/* Dynamically generate routes based on Artifacts found in the thiis.state.models array.
             Adding anotother artifact will generate another route to that artifacts description page. 
             ex. blackfootproject.com/artifacts/artifactname.*/}
              {this.state.models.map((object, i) => (
                <Route
                  key={i}
                  exact
                  path={`/explore/objects/${object.id}/:section`}
                >
                  {/* render={ */}
                  <Suspense fallback={<Loading />}>
                    <StoryPage
                      currentModel={object}
                      models={this.state.models}
                      changeName={this.changeName}
                      categories={this.state.categories}
                      setCategory={this.setCategory}
                      currentCategory={this.state.currentCategory}
                    />
                  </Suspense>
                  {/* } */}
                  {/* /> */}
                </Route>
              ))}
              {/* <Suspense> */}
              {this.state.models.map((object, i) => (
                <Route
                  key={i}
                  exact
                  path={`/rti_${object.id}`}
                  render={(props) => (
                    <Suspense fallback={<Loading />}>
                      <RTIViewer currentModel={`/webrti/${object.id}`} />
                    </Suspense>
                  )}
                />
              ))}
              {/* </Suspense> */}
              <Route>
                <Suspense fallback={<Loading />}>
                  <My404Page />
                </Suspense>
              </Route>
            </Switch>
            {/* Default route for error 404 */}

            {/* </Suspense> */}
            {/* <div className={AppStyle.navCluster}>
            <Hamburger destinations={this.state.destinations} />
            <NavBox name={"home"} link={"/"} />
            
          </div> */}

          </Router>
        </ThemeContext>
      </div>
    )
  }
}

export default App
