import React from 'react'
import Model from './Model/club_MAA_1911.glb'
import Icon from './Model/club_MAA_1911.png'
import PNG from './Model/club_MAA_1911.webp'
import littlePlumeYellowKidney from './Model/content/LittlePlume-YellowKidney.jpg'
import ACHaddonCambridgeU from './Model/content/A.C.Haddon-CambridgeU.png'
import ElkAntlerAudio from './Model/content/t-rex-roar.mp3'
import PaintAudio from './Model/content/t-rex-roar.mp3'
import BigWhipAudio from './Model/content/t-rex-roar.mp3'
export var club_MAA_1911 = {
  // unique id. I dont specifically think this is neccesary at the moment, but may prove useful later in development.
  // If not, we can remove it.
  id: 'club_MAA_1911',
  //Name of the Artifact.
  name: 'Big Whip',
  translation: 'Umakshapisthima',
  // translationAudio: BigWhipAudio,
  //mdl: the actual 3D object file (.glb or .gltf) is imported at the top of the
  //file, and associated to the model here.
  // mdl: Sheath,
  /*<button class="Hotspot" slot="hotspot-2" data-position="2.0256143595520166m 8.487512932713177m -8.192746933368085m" data-normal="-0.05959852537108738m 0.5707992079371752m -0.8189238548191677m" data-visibility-attribute="visible">
  </button><button class="Hotspot" slot="hotspot-3" data-position="2.778802731596354m 7.946290952872641m -7.962352493598493m" data-normal="0.822487305638788m -0.5353799122181668m -0.19204942503510605m" data-visibility-attribute="visible"></button>
  <button class="Hotspot" slot="hotspot-4" data-position="2.3707198909515848m 9.13835853455672m -7.281532910834866m" data-normal="0.4608608214664926m 0.8141798761670053m 0.35315496949909914m" data-visibility-attribute="visible"></button>
  <button class="Hotspot" slot="hotspot-5" data-position="1.079777376123345m 7.394414982666378m -8.106467027134656m" data-normal="-0.5325863094619012m -0.05049964476585609m 0.8448678055484624m" data-visibility-attribute="visible"></button>
  <button class="Hotspot" slot="hotspot-6" data-position="1.5473163073873728m 9.01245741825183m -7.859654676595451m" data-normal="-0.18516663152292648m 0.2380197825032357m -0.9534463286979322m" data-visibility-attribute="visible"></button>*/
  png: PNG,
  meta: {
    date: 'Early 20th Century',
    origin: 'Chief Little Plume, Amskapipiikani',
    tribe: 'Amskapipiikani',
    location: {
      lat: null,
      lng: null,
    },
    mapMarker: {
      lat: null,
      lng: null,
      icon: Icon,
    },
    museum: {
      val: 'Museum of Archaeology and Anthropology',
      ref: 'https://collections.maa.cam.ac.uk/objects/487672/',
    },
    materials: [
      //{ val: 'Antler', ref: 'https://www.youtube.com/' },
      'Antler',
      'Paint',
      'Hide',
    ],
    dimensions: {
      length: '35.5cm',
      width: '7cm',
      height: '11cm',
      // additions: [
      //   { key: 'key', val: { val: 'val', ref: 'https://www.youtube.com/' } },
      // ],
    },

    type: 'Weapon',
    // customs: [
    //   { key: 'key', val: 'val' },
    //   { key: 'key2', val: { val: 'val2', ref: 'ref2' } },
    // ],
  },
  Model: {
    mdl: Model,
    rti: '',
    icon: Icon,
    hotspots: [
      {
        name: 'Elk Antler',
        category: 'materials',
        dataPosition:
          '0.22005691034603103m 0.591623715419185m 0.6821479327864753m',
        dataNormal:
          '-0.6408225527848548m -0.48322002566474836m 0.5965273360365492m',
        content: [
          {
            type: 'text',
            // translationAudio: ElkAntlerAudio,
            description: (
              <div>
                <p>
                  Elk was commonly hunted by Blackfeet, who make a point of
                  using all parts of the animal for food and materials.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Paint',
        category: 'materials',
        dataPosition:
          '1.1669062251655031m 0.6471308563298677m 1.222589232473727m',
        dataNormal:
          '0.5091509754329474m -0.3957422521755572m 0.764299256874358m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The paint used to depict stories, events, or history were
                  harvested from the Earth. There were many plants, fungi, and
                  clay that were used to create dyes, which were used to add
                  color to the items that Blackfoot people were creating. Some
                  individuals still know how to create dyes or paint out of the
                  items that are naturally available within and outside of the
                  Blackfoot Confederacy. Commercial dyes and paints have made it
                  easier to add color over time and require less physical work
                  and time.
                </p>
                <p>
                  In Blackfoot, paint may be referred to in a few different
                  ways: <br />
                  <strong>isttsikoksspainni</strong> (Paint with a sticky
                  substance)
                  <br />
                  <strong>o’kspanohkio’taki</strong> (Paint for utilitarian
                  purposes)
                  <br />
                  <strong>i’kokatoo</strong> (Paint designs on a Tipi)
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: '3 Men',
        category: 'design',
        dataPosition:
          '0.6429144558592903m 1.6250616358881063m 1.3366646679750773m',
        dataNormal:
          '0.3443962580139901m 0.6398560453213415m 0.6870047006620076m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The museum records indicate that the symbols may represent the
                  three Assiniboine men that Little Plume fought with, killing
                  one of them.
                </p>
                <p>
                  Little Plume's encounter with the three men is well known. His
                  grandson, John Murray, recalls that Little Plume was hunting
                  and had killed an antelope on a hillside when he could feel
                  horses running on the ground. He spotted the three
                  Assiniboines coming at him and they retreated but he goaded
                  them to fight. He managed to kill one.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Double Zigzags',
        category: 'design',
        dataPosition:
          '-0.6636369637156715m -0.38792758429311025m -0.6038822052848773m',
        dataNormal:
          '-0.8261659773434188m 0.15219209903829028m 0.5424825737943226m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>Signify looking for the enemy.</p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Circles',
        category: 'design',
        dataPosition:
          '0.17110816435902687m 1.1635952883180298m 1.319188784119604m',
        dataNormal:
          '-0.6099779627737844m -0.31031344255981247m 0.7291312997650161m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The circles around the head of the club may signify the inner
                  and outer circle of tipis during akokat'ssin (Sun Dance). This
                  important yearly ceremony connects the Blackfoot to Natosi
                  (the sun).
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Hoofs',
        category: 'design',
        dataPosition:
          '-0.1468670533974002m -0.2579652032689863m -0.5982858995761391m',
        dataNormal:
          '0.7085842914712632m -0.013890070283051337m -0.7054894526693506m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Hoofs are a symbol of Little Plume’s successful coups. Little
                  Plume was a very well known and well-respected warrior
                  throughout his life. As a young boy he had counted coup on a
                  Cree man and stolen his weapon during a fight. He asked an
                  elder for his name in exchange of the weapon. Which is how
                  Little Plume was so named (BDL).
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  {' '}
                  <a href="https://www.blackfootdigitallibrary.com/digital/collection/bdl/id/1587/rec/5">
                    {' '}
                    BDL. Four Portraits of Little Plume from the Magee
                    Collection.
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  //description array. An array of js objects, used to store the artifact descriptions and
  //informational 'content' in categories pertaining to the 'title'.
  //Title and content are necessary keys, but the values can be changed.
  //additional description categories can be added, but must follow this format and cannot be added to the start.
  //First element must be the initial description at description[0]
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This war club belonged to Little Plume, an Amskapipiikani Chief
                known for being a great warrior, advocate for Blackfeet rights,
                and healer. You can learn more about him in the 'history'
                section.
              </p>
              <p>
                Blackfoot war clubs were used for warfare or ceremonial purposes
                (Native Languages of the Americas). The designs on the club tell
                stories about Little Plume's life as a warrior and ceremonial
                leader. Visit the 'design' section to learn more.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Lewis, Orrin, et al. “North American Indian Weapons”. Native
                Languages of the Americas. Accessed September 20, 2020.{' '}
                <a
                  href="http://www.native-languages.org/weapons.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.native-languages.org/weapons.htm
                </a>
              </p>
              <p>
                <a
                  href="https://collections.maa.cam.ac.uk/objects/487672/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Museum of Archeology and Anthropology. Description of E
                  1911.129.1.
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                This war club, recorded by the name Umakshapisthima (Big Whip),
                belonged to Little Plume, an influential Amskapipiikani Chief.
                Little Plume was well known for his victories in battle and
                involvement in treaties with other tribes and the U.S.
                government. His people lived and hunted on lands around the
                Teton River until President Grant issued an Executive Order
                diminishing the 1855 treaty lands and establishing an undivided
                reservation for the Blackfeet, Gros Ventre, Assiniboine, and
                Sioux (Montana Tribal History Timelines, 2017).
              </p>
            </div>
          ),
        },
        {
          type: 'quote',
          quote: (
            <div>
              <p>
                In this country our families have been reared, and taught the
                traditions of our great warriors, many seasons before the white
                man was heard of by us, and where our ancestors are buried.
                Neither our wishes nor our rights have been consulted in this
                new and unjust scheme to take our best country from us.
              </p>
            </div>
          ),
          person: 'Little Plume to President Ulysses Grant, 1874',
        },
        {
          type: 'image',
          title: 'littlePlumeYellowKidney',
          url: littlePlumeYellowKidney,
          description: (
            <div>
              <p>
                Little Plume and brother Yellow Kidney in 1910. Photographer by
                Edward Curtis famously edited out the clock when he first
                printed the photo.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          description: (
            <div>
              <p>
                After the formation of the Blackfeet reservation, Little Plume
                relocated along the Two Medicine River where some of his
                decendents still reside. His grandson, John Murray, recalls that
                Little Plume had the "gift of being able to develop a
                relationship and get along with people and still be successful
                at what he wanted to do."
              </p>
            </div>
          ),
        },
        {
          type: 'video',
          title: 'Little Plume',
          url: 'https://www.youtube.com/embed/bfT2a5SGDFA',
          embed: true,
          description: (
            <div>
              <p>
                Little Plume can be seen at the end of this video of the Sign
                Language Council in 1930
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                The club was one of several items acquired from Little Plume by
                Alfred Cort Haddon (1855-1940), a Reader in Ethnology at the
                University of Cambridge. Haddon met Little Plume during an
                expedition with the photographer Edward Curtis and student Eric
                Horniman in the summer of 1909. He wrote extensively of his
                experiences with Little Plume, including his observation of a
                medicine pipe ceremony and a sweat lodge ceremony (Gidley,
                1982). During this visit, Haddon and Horniman acquired several
                items from the Blackfoot, a few of which are also included on
                this site.
              </p>
            </div>
          ),
        },

        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                Little Plume’s war club and other Blackfeet items were donated
                by A.C. Haddon to the Museum of Archeology (MAA) and
                Anthropology at Cambridge University. It remains in Museum
                storage today. The MAA also holds a large collection of
                photographs taken during Haddon’s visit to Blackfeet territory
                in 1909.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Gidley, Mick. A. C. Haddon Joins Edward S. Curtis: An English
                Anthropologist among the Blackfeet, 1909. Montana. 1982.{' '}
                <a href="https://www.academia.edu/36053666/A_C_Haddon_Joins_Edward_S_Curtis_An_English_Anthropologist_Among_the_Blackfeet_1909">
                  https://www.academia.edu/36053666/A_C_Haddon_Joins_Edward_S_Curtis_An_English_Anthropologist_Among_the_Blackfeet_1909
                </a>
              </p>
              <p>
                "Montana Tribal History Timelines - Blackfeet Reservation
                Timeline". Indian Education Division, Montana Office of Public
                Instruction. 2017{' '}
                <a href="https://opi.mt.gov/Portals/182/Page%20Files/Indian%20Education/Social%20Studies/K-12%20Resources/Timelines%20for%20All%20Reservations.pdf">
                  https://opi.mt.gov/Portals/182/Page%20Files/Indian%20Education/Social%20Studies/K-12%20Resources/Timelines%20for%20All%20Reservations.pdf
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
    // {
    //   title: "Stories",
    //   content: [
    //     {
    //       type: "text",
    //       title: "The Woman who married Morning Star",
    //       description: (
    //         <div>
    //           <p>
    //             This story explains how the North Star came into existence, and
    //             also how we received one of our most important ceremonies.
    //           </p>
    //           <p>
    //             <a href="http://www.virtualmuseum.ca/edu/ViewLoitDa.do;jsessionid=EAF551A7FAFC4E248DC36B0D0072A2C7?method=preview&lang=EN&id=5220">
    //               As told by Mrs. Wolf Plume, Amsskaapipikani in 1911
    //             </a>
    //           </p>
    //         </div>
    //       ),
    //     },
    //   ],
    // },
  ],

  //categories: "All, Beading, Deerhide",
  // categories: ["Beading", "Deerhide"],
  categories: [
    {
      title: 'Materials',
      // options: ["Hide", "Beads", "Quills", "Bone", "Wood", "Stone"],
      options: [ 'Bone', 'Paint' ],
    },
    {
      title: 'Blackfoot Tribe',
      // options: ["Piikani", "Amskapipiikani", "Siksika", "Kainai"],
      options: [ 'Amskapipiikani' ],
    },
    {
      title: 'Museum Collection',
      // options: [
      //   "Museum of Archaeology and Anthropology",
      //   "The British Museum",
      //   "The Horniman Museum",
      // ],
      options: [ 'Museum of Archaeology and Anthropology' ],
    },
    {
      title: 'Theme',
      // options: [
      //   "Clothing",
      //   "Child Rearing",
      //   "Tools",
      //   "Pipes",
      //   "Cases",
      //   "Paint",
      // ],
      options: [ 'Weapons', 'Pictographs' ],
    },
  ],
}
