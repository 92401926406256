import React from 'react'
import Model from './Model/rattle_MAA_1929.glb'
import Icon from './Model/rattle_MAA_1929.png'
import PNG from './Model/rattle_MAA_1929.webp'

export var rattle_MAA_1929 = {
  id: 'rattle_MAA_1929',
  name: 'Rattles',
  translation: '',
  png: PNG,
  meta: {
    date: 'Before 1929',
    origin: 'Piikani',
    tribe: 'Piikani',
    materials: ['Fur', 'Sinew', 'Hide', 'Wood'],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'Museum of Archaeology and Anthropology',
      ref: 'https://collections.maa.cam.ac.uk/objects/487777/ ',
    },
    dimensions: {
      length: '',
      width: '',
      height: '',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    rti: '',
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Sinew',
        category: 'materials',
        dataPosition:
          '3.632622905798212m 3.096503264415782m -14.409695299040704m',
        dataNormal:
          '0.8978913652257308m 0.43173854546500295m -0.08598211798296714m',
        content: [
          {
            type: 'text',
            title: 'Neck Sinew',
            titleBlackfoot: 'Paakahtaan',
            description: (
              <div>
                <p>
                  Sinew is the tough fibrous tissues that unite muscle to bone
                  or bone to bone on the animal. This was often harvested from
                  animals that existed within the region such as deer and
                  buffalo and used to create items used for both ceremony and
                  everyday use. Sinew has to be stored properly to prevent
                  rotting.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Buffalo Hide',
        category: 'materials',
        dataPosition:
          '-2.504638838831298m 2.013329925899714m -11.371504022765926m',
        dataNormal:
          '-0.21649176729405803m 0.1667227130551956m 0.9619432684131691m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The rattles are made of two pieces of buffalo hide sewn
                  together with sinew.
                  <br />
                  <br />
                  Iinii (buffalo) were mostly hunted for food and Blackfoot
                  people would try to use all that the animal had to offer. This
                  was done out of respect, as they knew that the animal
                  sacrificed itself.
                  <br />
                  <br />
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather, from an animal, is a lengthy tedious process
                  that can span over a length of time. This can range from days
                  to weeks.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Buffalo Fur',
        category: 'materials',
        dataPosition:
          '-1.8324372602624428m 0.7026275315976291m -13.78406827612305m',
        dataNormal:
          '0.23958866375073845m -0.5438513176361395m -0.8042530798869039m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The collar of the rattle is wrapped in buffalo fur.
                  <br />
                  <br />
                  Buffalo were a staple of the Blackfoot way of life, providing
                  food, clothing, and shelter, until settlers decimated their
                  population to near extinction in the late 1800s.
                </p>
              </div>
            ),
          },
          {
            type: 'video',
            url: 'https://youtu.be/6LJfPMoGMAg',
            title: 'Iinnii Initiative: The Return of the Buffalo',
            embed: true,
            description: (
              <div>
                <p>
                  In 2016, 88 buffalo were reintroduced to Blackeet lands
                  through the Iinii Initiative.
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Rattles are used by ceremonialists in Blackfoot societies.
                Knowledge about such an item is sacred and cared for by people
                with transferred rites, a privilege passed down from one
                knowledge holder to another.
              </p>
            </div>
          ),
        },
        {
          type: 'quote',
          quote: (
            <div>
              <p>
                When you get a transferred rite, it’s a rebirth in life; it
                doesn’t matter how old you are. Once you have made that vow you
                have humbled yourself to the Creator.
              </p>
            </div>
          ),
          person: 'Dr. Reg Crowshoe & Geoff Crow Eagle',
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                <a
                  href="http://fourdirectionsteachings.com/transcripts/blackfoot.html."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dr. Reg Crowshoe and Geoff Crow Eagle. Piikani Blackfoot
                  Teaching. Four Directions Teachings. Accessed June 23, 2021.
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                These rattles belonged to someone from Piikani, but their name
                was not recorded when they were purchased. As the plains buffalo
                population had collapsed by the 1880s, it is likely that the
                rattles were made before this time.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                The rattles were purchased by Robert Rymill in August of 1929
                during his visit to the Piikani Nation. Rymill was a part of the
                Franklin Motor Expedition sent to Canada by Cambridge
                University’s Museum of Archeology and Anthropology (MAA) to
                collect traditional items from Indigenous people in the Canadian
                prairies. Over the course of their trip they purchased hundreds
                of items from Ojibwa, Cree, Tsuu T’ina, and Blackfoot
                communities. 199 of these were from Piikani, creating what is
                thought to be one of the largest known collections of Piikani
                artifacts (Brown, 2014).
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where are they now?',
          description: (
            <div>
              <p>
                At the end of the Franklin Motor Expidition’s journey across
                Canada and the US, the team shipped crates of the collection
                items to Cambridge University to become a part of the MAA. The
                MAA’s 1929 Annual Report notes “an extensive and valuable
                collection” made by R.R. Rymill for the museum (Brown, 2014).
                The rattles continue to be housed in the MAA’s storage in
                Cambridge, England.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Brown, Alison K. First Nations, Museums, Narrations: Stories of
                the 1929 Franklin Motor Expedition to the Canadian Prairies. UBC
                Press. 2014.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: ['Fur', 'Sinew', 'Hide', 'Wood'],
    },
    {
      title: 'Blackfoot Tribe',
      options: ['Piikani'],
    },
    {
      title: 'Museum Collection',
      options: ['Museum of Archaeology and Anthropology'],
    },
    {
      title: 'Theme',
      options: ['Ceremonial'],
    },
  ],
}
