import React from 'react'
import Model from './Model/stirrups_BM_1958.glb'
import Icon from './Model/stirrups_BM_1958.png'
import PNG from './Model/stirrups_BM_1958.webp'

import horses from './Model/content/N-14612-ACH2.jpg'

export var stirrups_BM_1958 = {
  id: 'stirrups_BM_1958',
  name: 'Stirrups',
  translation: 'Saapíkaakia’stis',
  png: PNG,
  meta: {
    date: '1850-1858',
    origin: 'Blackfoot or Cree',
    tribe: '',
    materials: [ 'Sinew', 'Wood', 'Rawhide' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The British Museum',
      ref: 'https://www.britishmuseum.org/collection/object/E_Am1982-28-12-a-b',
    },
    dimensions: {
      length: '16.5cm',
      width: '13cm',
      height: '7.5cm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Rawhide',
        category: 'materials',
        dataPosition:
          '1.195824127546771m -1.2095895304382402m 0.5484528689267645m',
        dataNormal:
          '-0.024714056490511296m 0.9508037547598348m 0.30880646908117704m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Rawhide was prepared by cleaning and dehairing the skin. It
                  was then stretched and dried in the sun. This was done by
                  staking it above the ground, and scraping it to an even
                  thickness. Prickly pear juice was applied for protection
                  before the hide was painted. The hide needed to be dampened
                  with water before you started this process. This process
                  created a stiff but durable leather that was waterproof.
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  <a
                    href="http://www.aboriginalperspectives.uregina.ca/workshops/workshop2011/background.shtml"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vanessa Halas and Alexandra Poetker. “Parfleche”. Aboriginal
                    Perspectives. University of Regina. 2011.
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Neck Sinew',
        category: 'materials',
        dataPosition:
          '0.06361813291868512m 3.8669446593036367m 0.818379356361441m',
        dataNormal:
          '-0.7692057447770885m 0.49057052613377283m 0.4094668253848632m',
        content: [
          {
            type: 'text',
            title: 'Neck sinew',
            titleBlackfoot: 'Paakahtaan',
            description: (
              <div>
                <p>
                  Sinew is the tough fibrous tissues that unite muscle to bone
                  or bone to bone on the animal. It lies along the backbone of
                  the animal, once the meat is scraped off you plaster the strip
                  onto a flat service to create sinew (Hungrywolf, 1982, p.
                  240). This was often harvested from animals that existed
                  within the region such as deer and buffalo and used to create
                  items used for both ceremony and everyday use. Sinew had to be
                  stored properly to prevent rotting. It is important to note
                  that although commercial thread has been introduced to
                  Indigenous populations, there are still individuals who
                  harvest material and create sinew using traditional methods
                  that have been transferred to them from their family members
                  or kin.
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  Hungry Wolf, Beverly. (1982). The ways of my grandmothers (1st
                  Quill ed.). New York: Quill.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Wood',
        category: 'materials',
        dataPosition:
          '-1.6677200404746673m 2.9651241602537493m 1.06784287788913m',
        dataNormal:
          '-0.5883275759787406m 0.3622266320739368m -0.7229540305990085m',
        content: [
          {
            type: 'text',
            title: 'Piece of wood or wood shaving',
            titleBlackfoot: 'Piiksska',
            description: (
              <div>
                <p>Bent wood spans across the top of the stirrups.</p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                When translating stirrup to English it means that which is used
                to place one’s foot. Ponokaomitai-ksi (horses) were introduced
                to the Blackfoot in the mid to late 1700’s (Pard 2014 as cited
                in Bethke 2019). This brought on the production of new
                technology needed to incorporate the horse successfully into the
                every day-to-day activities of the Blackfoot people. Stirrups
                were one of those items made to ensure safety and made
                transportation of an individual on the horse easier.
                <br />
                <br />
                Stirrups are now made of aluminum, stainless steel, plastic or a
                mix of some of these three.
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Horses',
          url: horses,
          description: (
            <div>
              <p>
                Family on horseback: Yellow Owl and his wife Margaret Spotted
                Bear with their children. From the{' '}
                <a
                  href="https://collections.maa.cam.ac.uk/photographs/307884"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MAA photo archives.
                </a>
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Bethke, Brandi. "Revisiting the Horse in Blackfoot Culture:
                Understanding the Development of Nomadic Pastoralism on the
                North American Plains." International Journal of Historical
                Archaeology, vol. 24, no. 1, 2019;2020;, pp. 44-61.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The museum records indicate the stirrups were made between 1850
                and 1858. They may have been Cree based on the record-keeping of
                the Major who brought them to England.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition ',
          description: (
            <div>
              <p>
                In 1858, Major George Seton somehow acquired the stirrups from
                the Blackfoot or Cree. He inscripted each with '
                <em>North American Indian Stirrup. Rupert's land Augt '58</em>'.
                The British Museum purchased it from the Warwickshire Museum.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                The stirrups are currently in the Africa, Oceania & the Americas
                department of The British Museum storage in London, England.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Sinew', 'Wood', 'Hide' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [ '' ],
    },
    {
      title: 'Museum Collection',
      options: [ 'The British Museum' ],
    },
    {
      title: 'Theme',
      options: [ 'Equestrian' ],
    },
  ],
}
