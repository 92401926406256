import React from 'react'
import style from './style/Loading.module.css'
const SVG = (
  <svg
    viewBox="0 0 64.85 64.85"
    className={style.loading}
    // name="loading"
    // id="loading"
  >
    <g className={style.spin} name="loading">
      <circle className={style.c1} cx="32.43" cy="3.43" r="3.43" />
      <circle className={style.c2} cx="43.3" cy="5.54" r="3.43" />
      <circle className={style.c3} cx="52.93" cy="11.92" r="3.43" />
      <circle className={style.c4} cx="59.13" cy="21.11" r="3.43" />
      <circle className={style.c5} cx="61.43" cy="32.43" r="3.43" />
      <circle className={style.c6} cx="59.31" cy="43.3" r="3.43" />
      <circle className={style.c7} cx="52.93" cy="52.93" r="3.43" />
      <circle className={style.c8} cx="43.75" cy="59.13" r="3.43" />
      <circle className={style.c9} cx="32.43" cy="61.43" r="3.43" />
      <circle className={style.c10} cx="21.55" cy="59.31" r="3.43" />
      <circle className={style.c11} cx="11.92" cy="52.93" r="3.43" />
      <circle className={style.c12} cx="5.73" cy="43.75" r="3.43" />
      <circle className={style.c13} cx="3.43" cy="32.43" r="3.43" />
      <circle className={style.c14} cx="5.54" cy="21.55" r="3.43" />
      <circle className={style.c15} cx="11.92" cy="11.92" r="3.43" />
      <circle className={style.c16} cx="21.11" cy="5.73" r="3.43" />
    </g>
  </svg>
)
export default function Loading() {
  return SVG
}
