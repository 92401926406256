import React from 'react'
import Model from './Model/bag_BM_1903.glb'
import Icon from './Model/bag_BM_1903.png'
import PNG from './Model/bag_BM_1903.webp'

import windspeaker from './Model/content/windspeaker.jpg'

export var bag_BM_1903 = {
  id: 'bag_BM_1903',
  name: 'Parfleche',
  translation: 'Asootsímaan',
  png: PNG,
  meta: {
    date: 'Before 1901',
    origin: 'Weasel Fat, Kainai',
    tribe: 'Kainai',
    materials: [ 'Paint', 'Rawhide' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The British Museum',
      ref: 'https://www.britishmuseum.org/collection/object/E_Am1903-79',
    },
    dimensions: {
      width: '36cm',
      height: '61cm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    rti: '',
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Rawhide',
        category: 'materials',
        dataPosition:
          '2.6789853362017144m 0.8573022887325134m -0.17829996665366288m',
        dataNormal:
          '0.08750716708974474m 0.9500803151183479m 0.2994827048972082m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Parfleche, or rawhide, was prepared by cleaning and dehairing
                  the skin. It was then stretched and dried in the sun. This was
                  done by staking it above the ground, and scraping it to an
                  even thickness. Prickly pear juice was applied for protection
                  before the hide was painted. The hide needed to be dampened
                  with water before you started this process. This process
                  created a stiff but durable leather that was waterproof.
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  <a
                    href="http://www.aboriginalperspectives.uregina.ca/workshops/workshop2011/background.shtml"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vanessa Halas and Alexandra Poetker. “Parfleche”. Aboriginal
                    Perspectives. University of Regina. 2011.
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Paint',
        category: 'materials',
        dataPosition:
          '-1.5837322822740427m -0.15447739495146628m 1.2660298655843842m',
        dataNormal:
          '-0.06526463922118157m 0.9280700561718783m 0.3666421930226466m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The substances used to design parfleche cases were similar to
                  paints that were available in various locations around the
                  world during the time. Blackfoot people used plants, fungi,
                  and clay to create dyes (or paint-like substances), which were
                  used to add color to the items that Blackfoot people were
                  creating. There are individuals that still know how to create
                  dyes or paint out of the items that are naturally available
                  within, and outside, of the Blackfoot Confederacy. Today
                  commercial paints and dyes are used to design some items.
                  Commercial dyes and paints have made it easier to add color
                  over time and require less physical work and time.
                </p>
                <p>
                  In Blackfoot, paint may be referred to in a few different
                  ways: <br />
                  <strong>isttsikoksspainni</strong> (Paint with a sticky
                  substance)
                  <br />
                  <strong>o’kspanohkio’taki</strong> (Paint for utilitarian
                  purposes)
                  <br />
                  <strong>i’kokatoo</strong> (Paint designs on a Tipi)
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Parfleche was considered to be very useful for storage and
                carrying cases. They were often made in pairs and carried on the
                back or suspended on each side of the horse to be transported.
                This parfleche bag may have been used to store food such as
                pemmican, or other dried goods like dried deer meat, wild
                turnips, or prunes. It also could have been used to carry
                personal items for the individual. It was a safe and convenient
                way of carrying items. The way it is folded ensures that items
                will not fall out or be impacted by the weathering elements.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                According to museum records, this bag came from a Kainai man
                named Weasel Fat. The word parfleche came from French fur
                traders. “Parer” means “to defend” or “turn away”, and “fleche”
                is the word for arrow. This term is referring to shields made
                out of rawhide. Parfleche bags are made using one single piece
                of rawhide, folded much like an envelope, so that items would be
                safe from insects or weather elements and to prevent them from
                falling out of the bag. This is one type of parfleche
                containers, some are cylinder shaped or in the shape of a box.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                The bag is listed as being purchased by Maude Deane-Freeman,
                wife of a kinnonna (indian agent) stationed on the Blood
                (Kainai) reserve from 1884 and 1901. They collected 268 items in
                total and recorded the names of who they belonged to, which was
                apparently rare for items from Kainai (Brownstone, 2002).
              </p>
              <p>
                Kinnonna were appointed by the government to control the rights
                and lives of Indigenous peoples on reserves. They followed
                policies put in place by the government and had authority over
                whether indigenous peoples could trade or travel and enforced
                children being sent to residential schools. After the buffalo
                disappeared in the 1870s, Blackfoot people faced severe
                hardships and eventually had to rely on aid from the government
                to survive. This aid was administered by the kinnonna.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                In 1901 the Deane-Freemans moved to Vancouver and their
                collection was sold to the Royal Ontario Museum (ROM) and the
                British Museum in 1903. This bag went to the British Musuem and
                is currently housed in their storage in London, England.
              </p>
              <p>
                In 2002 the bag returned to Blackfoot territory for the{' '}
                <a
                  href="https://www.galtmuseum.com/exhibit/akaitapiiwa-ancestors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Akaitapiiwa/Ancestors
                </a>{' '}
                exhibition at the Galt Museum and Archives. The exhibition was
                initiated when Louis Soop, a member of Kainai, discovered the
                items at the ROM and then visited the items at The British
                Museum with Rufus Goodstriker.
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'article',
          url: windspeaker,
          description: (
            <div>
              <p>
                An article in Windspeaker about the Deane-Freeman collection by
                Sheri Narine.
              </p>
            </div>
          ),
        }, {
          type: 'references',
          references: (
            <div>
              <p>
                <a
                  href="http://www.aboriginalperspectives.uregina.ca/workshops/workshop2011/background.shtml"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Arni Brownstone. “Ancestors: the Deane-Freeman Collections from the Bloods”. American Indian Art Magazine. 2002.
                </a>
              </p>
              <p>
                <a
                  href="https://www.ammsa.com/publications/windspeaker/collection-kainai-artifacts-galt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Shari Narine, "Collection of Kainai artifacts at the Galt". Windspeaker Publication.2002.
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Designs were consistent across nations, as they preserve and
                allow for knowledge transmission to occur between individuals
                and generations. This process required the designs, symbolisms,
                meanings and techniques to be transferred from one individual to
                the next, with Elders guiding the process of preserving,
                practicing, and engaging in oral history and knowledge
                practices, as well as the physical creation of the case.
                <br />
                <br />
                Parfleche bags were usually decorated with symmetric,
                geometrical shapes. Many of the parfleche bags usually had no
                more than four colours, like green, red, yellow, and black,
                which were used to create designs on the parfleche containers.
                There are earlier records indicating that earlier collections
                had graphics that symobolized maps and included pictorial
                records of the surrounding land.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                <a
                  href="http://www.aboriginalperspectives.uregina.ca/workshops/workshop2011/background.shtml"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vanessa Halas and Alexandra Poetker. “Parfleche”. Aboriginal
                  Perspectives. University of Regina. 2011.
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Paint', 'Hide' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [ 'Kainai' ],
    },
    {
      title: 'Museum Collection',
      options: [ 'The British Museum' ],
    },
    {
      title: 'Theme',
      options: [ 'Hidework', 'Cases' ],
    },
  ],
}
