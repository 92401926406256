import React, { Component } from 'react'
import PropTypes from 'prop-types'
export default class ThemeContext extends Component {
  state = {
    theme: this.props.defaultTheme,
  }
  setInitTheme = (theme) => {
    var setTheme = this.props.defaultTheme

    const darkOS = window.matchMedia('(prefers-color-scheme: dark)').matches
    const cachedTheme = localStorage.getItem('theme')
    if (cachedTheme) {
      setTheme = JSON.parse(cachedTheme)
    } else if (darkOS) {
      setTheme = 'dark'
    } else {
      setTheme = this.props.defaultTheme
    }
    // setTheme = "dark";
    //  if (setTheme !== this.state.theme) {
    this.setState({ theme: setTheme })
    // }
    if (this.props.tempTheme === false) {
      localStorage.setItem('theme', JSON.stringify(setTheme))
    }

    this.applyTheme(this.props.themes, setTheme)
    this.props.setAppTheme(setTheme)
  }

  setTheme = () => {
    // if (this.state.theme === "dark") {
    //   this.setState({ theme: "light" });
    //   localStorage.setItem("theme", JSON.stringify("light"));
    // } else if (this.state.theme === "light") {
    //   this.setState({ theme: "dark" });
    //   localStorage.setItem("theme", JSON.stringify("dark"));
    // }
    if (this.props.defaultTheme !== this.state.theme)
      this.setState({ theme: this.props.defaultTheme })
    if (this.props.tempTheme === false) {
      localStorage.setItem('theme', JSON.stringify(this.props.defaultTheme))
    }
  }

  applyTheme = (themes, currentTheme) => {
    document.body.style.setProperty(
      '--secondary-color',
      themes[currentTheme].secondaryColor,
    )
    document.body.style.setProperty(
      '--primary-color',
      themes[currentTheme].primaryColor,
    )
    document.body.style.setProperty(
      '--tertiary-color',
      themes[currentTheme].tertiaryColor,
    )
    document.body.style.setProperty(
      '--quaternary-color',
      themes[currentTheme].quaternaryColor,
    )
    // document.body.style.setProperty(
    //   "--background-color",
    //   themes[currentTheme].backgroundColor
    // );
    document.body.style.setProperty(
      '--background',
      themes[currentTheme].background,
    )
    document.body.style.setProperty(
      '--nav-background',
      themes[currentTheme].navBackground,
    )
    document.body.style.setProperty(
      '--nav-slide-opacity',
      themes[currentTheme].navSlideOpacity,
    )
  }

  componentDidUpdate() {
    this.setTheme(this.props.defaultTheme, false)
    this.applyTheme(this.props.themes, this.state.theme)
  }

  render() {
    return <div style={{ height: '100vh' }}>{this.props.children}</div>
  }
}

ThemeContext.propTypes = {
  defaultTheme: PropTypes.string,
  themes: PropTypes.objectOf(
    PropTypes.shape({
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      tertiaryColor: PropTypes.string,
      quaternaryColor: PropTypes.string,
      backgroundColor: PropTypes.string,
      background: PropTypes.string,
      navBackground: PropTypes.string,
      navSlideOpacity: PropTypes.string,
    }),
  ),
}
