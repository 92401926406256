import React from 'react'
import Model from './Model/buffalostone_HM_1909.glb'
import Icon from './Model/buffalostone_HM_1909.png'
import PNG from './Model/buffalostone_HM_1909.webp'

import Inisskimm from './Model/content/Inisskimm.jpeg'
import TwoMedicineRiver from './Model/content/TwoMedicineRiver.jpeg'

export var buffalostone_HM_1909 = {
  id: 'buffalostone_HM_1909',
  name: 'Buffalo Stone',
  translation: 'Inisskimm',
  png: PNG,
  meta: {
    date: 'Before 1909',
    origin: 'Amskapipiikani',
    tribe: 'Amskapipiikani',
    materials: [ 'Stone' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The Horniman Museum',
      ref: 'https://www.horniman.ac.uk/object/30.296',
    },
    dimensions: {
      length: '40mm',
      width: '17mm',
      height: '20mm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    rti: '',
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Stone',
        category: 'materials',
        dataPosition:
          '0.11758532644382469m 0.2886070762204306m -0.06882551610498194m',
        dataNormal:
          '0.5219285575365897m 0.8421150902367194m -0.13576728480520153m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The stone was likely in a river bed where it eroded into the
                  shape of a buffalo over time. Other inissmkimm’s are made of
                  ammonite fossils which are found throughout the landscape.
                </p>
              </div>
            ),
          },
        ],
      },

      {
        name: 'Printing',
        category: 'materials',
        dataPosition:
          '-0.17472950141851495m 0.06397669159236861m -0.1454859559106907m',
        dataNormal:
          '-0.5123527976313074m -0.1320698909501021m -0.8485588692977057m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  At one point, it was common for museums to mark new
                  acquisitions with their accession numbers directly on the
                  item. ‘30.296’ has been marked twice on the carving.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Shape',
        category: 'design',
        dataPosition:
          '-0.04957101687862897m 0.21952537042801856m 0.17667842429042913m',
        dataNormal:
          '0.24423229748851438m 0.6639592334566586m 0.7067593092214124m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  This stone has been formed into the shape of an iinii
                  (buffalo). Buffalo were a staple of the Blackfoot way of life,
                  providing food, clothing, and shelter, until settlers
                  decimated their population to near extinction in the late
                  1800s.
                </p>
              </div>
            ),
          },
          {
            type: 'video',
            url: 'https://youtube.com/embed/6LJfPMoGMAg',
            title: 'Iinnii Initiative: The Return of the Buffalo',
            embed: true,
            description: (
              <div>
                <p>
                  In 2016, 88 buffalo were reintroduced to Blackeet lands
                  through the Iinii Initiative.
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This item looks like an inisskimm, or buffalo calling stone,
                which are sacred to Blackfoot people. Inisskimm’s are stones or
                ammonite fossils found in the shape of a buffalo and are known
                as gifts from the iinii (buffalo). It is significant to have an
                inisskimm in your possession as it brings good fortune and
                prosperity. It is custom to have an inisskimm transferred to you
                by an Elder.
                <br />
                <br />
                We are unsure if this item was kept as an inisskimm, although it
                does appear to be in the shape of a buffalo.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Stories',
          description: (
            <div>
              <p>
                <strong>
                  <a
                    href="https://www.glenbow.org/blackfoot/EN/html/traditional_stories.htm#iniskim"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Iniskim (Buffalo Calling Stone)
                  </a>
                </strong>{' '}
                <br />
                How Weasel Woman found the ceremony to call the buffalo (In
                English and Blackfoot)
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The original maker and holder of this inisskimm was not recorded
                but it is thought to have come from someone living near the Two
                Medicine River in Blackfeet Territory. This smaller stone
                inisskimm would have been found by the river, where it would
                have been eroded into this shape over time.
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Two Medicine River',
          url: TwoMedicineRiver,
          description: (
            <div>
              <p>
                "Two Medicine River", Photo by Edward Curtis published in The
                North American Indian, v. VI in 1911.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          description: (
            <div>
              <p>
                The Horniman Museum identifies the item as possibly being worn
                by a woman as a fertility charm. Wrapping may have also
                contributed to the item’s shape.
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Inisskimm',
          url: Inisskimm,
          description: (
            <div>
              <p>Inisskimm in leather cases. Photo by Edward Curtis in 1911</p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                This inisskimm was likely collected from the Blackfeet in 1909
                by John Eric Horniman, the grandson of Frederick Horniman
                (founder of The Horniman Museum and Gardens). Eric was part of
                the expedition with A.C. Haddon and Edward Curtis to visit the
                Blackfeet and document their culture. Haddon also acquired many
                items that were donated to the MAA.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now? ',
          description: (
            <div>
              <p>
                Eric Horniman donated items he collected from his visit to the
                Blackfeet to his family's museum in 1913. This iniskim is now
                kept in storage at The Horniman Museum and Gardens in Forest
                Hill, London, England.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Stone' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [ 'Amskapipiikani' ],
    },
    {
      title: 'Museum Collection',
      options: [ 'The Horniman Museum' ],
    },
    {
      title: 'Theme',
      options: [ 'Spirituality' ],
    },
  ],
}
