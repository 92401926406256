import React from 'react'
import Model from './Model/dress_BM_1900.glb'
import Icon from './Model/dress_BM_1900.png'
import PNG from './Model/dress_BM_1900.webp'

import GirlsDresses from './Model/content/GirlsDresses.14633.ACH2.jpg'
import Zigzagdress from './Model/content/Am-GN2345.png'
import Oldsun from './Model/content/old-sun.jpeg'

export var dress_BM_1900 = {
  id: 'dress_BM_1900',
  name: "Girl's Dress",
  translation: '',
  png: PNG,
  meta: {
    date: 'Before 1906',
    origin: 'Pincher Creek',
    tribe: 'Siksika',
    materials: [ 'Hide', 'Brass', 'Beads', 'Sinew', 'Paint' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The British Museum',
      ref: 'https://www.britishmuseum.org/collection/object/E_Am1985-11-1',
    },
    dimensions: {
      length: '3cm',
      width: '140cm',
      height: '125cm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Ziz Zags',
        category: 'design',
        dataPosition:
          '-0.6004458047062947m 2.171972892943291m 0.7826672730986329m',
        dataNormal:
          '0.021317879901139866m 0.11810026912925074m 0.9927728211570456m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The beaded designs on dresses represent their marital status
                  with zig-zag bands indicating unmarried and straight bands
                  indicating married. This photo shows two girls with different
                  style bands on their dresses. that have been identified as
                  Suzie Russel and Cecile Russell, daughters of Billy Russel, by
                  Blackfoot Elders during a photo workshop in Browning Montana
                  (2014).
                </p>
              </div>
            ),
          },
          {
            type: 'image',
            title: 'Girls Dresses',
            url: GirlsDresses,
            description: (
              <div>
                <p>
                  Photo by Eric Horniman and is part of the MAA photo archive.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Decoration',
        category: 'design',
        dataPosition:
          '-0.85404585229154m 1.1019302868646461m 0.690211949012569m',
        dataNormal:
          '-0.25845393224700886m -0.06307502262272086m 0.9639621913888542m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Finger rings, pendants, metal bells, brass studs, and thimbles
                  were used to decorate favorite dresses. This style remained
                  with the Blackfoot until trade cloth gradually replaced tanned
                  animal hides as daily clothing.
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  <a
                    href="http://www.blackfootcrossing.ca/survival.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    “Clothing: Survival: Our Culture” Blackfoot Crossing
                    (Currently under construction).
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Tanned Hide',
        category: 'materials',
        dataPosition:
          '-0.3702966053280947m -0.27403948135902595m -0.7947409712754943m',
        dataNormal:
          '-0.03418550679985919m 0.011941612186892705m -0.9993441594481928m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather is a lengthy tedious process that can span over a
                  length of time. This can range from days to weeks. Tanned
                  hides were central to survival, used for shelter, clothes,
                  bags, and more.
                  <br />
                  <br />
                  At least two large hides were used to create a dress for a
                  woman. A child’s dress may take only one hide. Dresses were
                  made with the heads of the hides at the bottom, the necks and
                  forelegs were left in their natural shapes to give the dresses
                  the characteristic wavy bottoms that were fringed.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Paint',
        category: 'materials',
        dataPosition:
          '-0.10445164386577077m -1.7787957708453965m -0.823581406997243m',
        dataNormal:
          '0.3775379173077272m 0.007021404615814269m -0.925967505300356m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The substances used to design parfleche cases were similar to
                  paints that were available in various locations around the
                  world during the time. Blackfoot people used plants, fungi,
                  and clay to create dyes (or paint-like substances), which were
                  used to add color to the items that Blackfoot people were
                  creating. There are individuals that still know how to create
                  dyes or paint out of the items that are naturally available
                  within, and outside, of the Blackfoot Confederacy. Today
                  commercial paints and dyes are used to design some items.
                  Commercial dyes and paints have made it easier to add color
                  over time and require less physical work and time.
                </p>
                <p>
                  In Blackfoot, paint may be referred to in a few different
                  ways: <br />
                  <strong>isttsikoksspainni</strong> (Paint with a sticky
                  substance)
                  <br />
                  <strong>o’kspanohkio’taki</strong> (Paint for utilitarian
                  purposes)
                  <br />
                  <strong>i’kokatoo</strong> (Paint designs on a Tipi)
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Sinew',
        category: 'materials',
        dataPosition:
          '-0.959077411642096m 0.576533151400334m 0.6320678434587009m',
        dataNormal:
          '-0.7138480094021556m -0.05828966331061628m 0.6978705715415394m',
        content: [
          {
            type: 'text',
            title: 'Neck sinew',
            titleBlackfoot: 'Paakahtaan',
            description: (
              <div>
                <p>
                  Sinew is the tough fibrous tissues that unite muscle to bone
                  or bone to bone on the animal. It lies along the backbone of
                  the animal, once the meat is scraped off you plaster the strip
                  onto a flat service to create sinew (Hungrywolf, 1982, p.
                  240). This was often harvested from animals that existed
                  within the region such as deer and buffalo and used to create
                  items used for both ceremony and everyday use. Sinew had to be
                  stored properly to prevent rotting. It is important to note
                  that although commercial thread has been introduced to
                  Indigenous populations, there are still individuals who
                  harvest material and create sinew using traditional methods
                  that have been transferred to them from their family members
                  or kin.
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  Hungry Wolf, Beverly. (1982). The ways of my grandmothers (1st
                  Quill ed.). New York: Quill.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Beads',
        category: 'materials',
        dataPosition:
          '-1.3457747064613645m 2.2872762057450187m 0.4963810903828104m',
        dataNormal:
          '-0.4073490877237483m 0.313171426381844m 0.857898815961761m',
        content: [
          {
            type: 'text',
            title: 'Bead',
            titleBlackfoot: 'Áótooksiinaattsi',
            description: (
              <div>
                <p>
                  Colonial expansion brought new materials with the settlers.
                  Among these items were beads. Beads were quickly recognized as
                  being valuable and were highly sought after by Indigenous
                  people when trade routes were established (Gray, 2017). The
                  seed bead was introduced in the 18th century and was smaller
                  than the pony beads, which arrived in the 16th century(as
                  cited in Gray, 2017). The beads used to create this item were
                  manufactured and brought over by settlers as a means of trade
                  materials.
                </p>
                <p>
                  Once large quantities of these beads became available,
                  beadwork on clothing and personal items expanded. Beadwork
                  reached its “high point” from 1890 – 1920, when almost all
                  items were made with some beadwork on them.
                </p>
                <p>
                  More than decoration, beadwork is an expression of the
                  beader’s identity, spiritual beliefs, and highlights the
                  connections to their kinship alliances. Designs can be passed
                  down through families and have different meanings and
                  teachings (Belcourt).
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  Belcourt, Christi. Beadwork: First Peoples' Beading History
                  and Techniques. Owen Sound, Ont: Ningwakwe Learning Press,
                  2010.
                </p>
                <p>
                  Gray, M. (2017). Beads: Symbols of Indigenous Cultural
                  Resilience and Value (Unpublished Master’s Thesis). University
                  of Toronto, Toronto, Ontario.
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Blackfoot women’s principal garment was a sleeveless skin dress
                that fell to the ankles. Similar to a modern day slip with
                straps over the shoulders. In cooler weather sleeves were added
                to the dress to help retain warmth. As trade items began to
                reach Blackfoot tribes changes took place in their style of
                dress. Women began to wear dresses with full elbow length
                sleeves, artfully embroidered with narrow bands of glass trade
                beads, pendants, metal bells, brass studs, thimbles, and bits of
                trade cloth. Eventually trade cloth replaced tanned animal hides
                when it involved making clothing.
                <br />
                <br />
                Finger rings, pendants, metal bells, brass studs, and thimbles
                were used to decorate favorite dresses. This style remained with
                the Blackfoot until trade cloth gradually replaced tanned animal
                hides as daily clothing.
              </p>
              <p>
                In Blackfoot, the dress may be referred to in a few different
                ways: <br />
                <strong>Yáaksinao’si</strong>(dress for a specific occasion,
                e.g. ceremony)
                <br />
                <strong>Inao’si</strong>( (dress for a specific occasion)
                <br />
                <strong>Awakaasistot’oos</strong>( (deer skin clothing)
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                <a
                  href="http://www.blackfootcrossing.ca/survival.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Clothing: Survival: Our Culture” Blackfoot Crossing
                </a>{' '}
                (page currently under construction)
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origin',
          description: (
            <div>
              <p>
                The level of decoration on the dress means that the girl who wore it was considered special
                in the family and society. The favourite child or grandchild are referred to as mini’pokaa
                (Blackfoot Gallery Committee).
              </p>
            </div>
          ),
        },
        {
            type: 'image',
            title: 'Frank-and-Girl',
            url: Zigzagdress,
            description: (
              <div>
                <p>
                  Portrait of Frank Tried to Fly with a girl wearing a similar dress (1913, Gleichen).
                </p>
              </div>
            ),
          },
        {
          type: 'quote',
          quote: (
            <div>
              <p>
                Parents would have brought her to all the ceremonies. She would
                have a special role, like at the end berry soup would be served,
                and elders would pay the little girl to lick their bowls clean.
              </p>
            </div>
          ),
          person: 'Danielle Heavy Head',
        },
        {
          type: 'text',
          description: (
            <div>
              <p>
                The dress Am1985.11.1 has an old label attached which the British Museum Collections Manager thinks pre-dates its accession into the Museum. This label says the dress belonged to Old Sun’s daughter. Na to sa pi (Old Sun) was a successful warrior and medicine man who was chief of Siksika in the 19th century. He signed Treaty 7 for his band and Old Sun College was established in 1971 in his name (Old Sun Community College).
              </p>
               <p> It could have been the donor Mrs M. S. Kendall who wrote the label or perhaps her father Francis Merceron. Mrs Kendall provided the information to the museum that her father had collected the items in Pincher Creek around 1906. Pincher Creek is close by the Piikani and Kainai reservations.</p>
            </div>
          ),
        },
        {
            type: 'image',
            title: 'Old Sun and Calf Old Woman',
            url: Oldsun,
            description: (
              <div>
                <p>
                  Old Sun and his wife, Calf Old Woman.
                </p>
              </div>
            ),
          },
        {
          type: 'text',
          title: 'Aquisition',
          description: (
            <div>
              <p>
                We know from Ship Passenger Lists that Francis Merceron visited Canada and the US several times for long trips in the late 1800's and early 1900s including a trip on 29 Mar 1906 from Liverpool UK to Halifax Ca.  The Crowsnest Branch of the Canadian Pacific Railway passed close by to Pincher Creek, so perhaps he travelled overland by rail to SW Alberta. He also appears on the 1906 Canadian Census, lodging in Livingstone near Pincher Creek. This is the year that Pincher Creek was incorporated as a town. Merceron had <a href="https://www.bac-lac.gc.ca/eng/discover/land/land-grants-western-canada-1870-1930/Pages/list.aspx?Names=merceron&" target="_blank" rel="noopener noreferrer">three land grants</a> around Cowley near Pincher Creek. We don’t know how much he travelled in Alberta, where or how he acquired the Blackfoot items (<a href="https://www.britishmuseum.org/collection/search?keyword=merceron" target="_blank" rel="noopener noreferrer">others can be seen here</a>). He returned to the UK and married Gladys Muirhead Russell in 1907. They made a trip to Canada together in 1911. Francis Merceron was descended from a French Huguenot family in the East End of London. He was a Justice of the Peace. He had houses in London and Hampshire. He died in Hampshire UK in 1941. 
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
               The items he acquired around 1906 in Pincher Creek were given to the British Museum by his daughter in 1985. These items are the dress, a set of leggings, two beaded belts, four sets of moccasins, a beaded club and a tobacco pipe stem (<a href="https://www.britishmuseum.org/collection/search?keyword=merceron" target="_blank" rel="noopener noreferrer">can been seen here</a>).
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
             "Photographic Print (black and white); glass negative",  <a
                  href="https://www.britishmuseum.org/collection/object/EA_Am-G-N-2345"
                  target="_blank"
                  rel="noopener noreferrer"
                >The British Museum</a>.
                </p>
                <p>"Old Sun and Wife", Ernest Cline. <a href="https://www.blackfootdigitallibrary.com/digital/collection/bdl/id/402/rec/1" target="_blank"
                  rel="noopener noreferrer">Blackfoot Digital Library</a>. Photograph.</p>
              
              <p>
              <a
                  href="https://oldsuncollege.net/discover-old-sun/old-sun-history.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >"Old Sun History"</a>, Old Sun Community College. Accessed Nov 15, 2021.
                </p>
              <p>
                <a
                  href="https://www.glenbow.org/blackfoot/EN/html/during_the_day.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Niitsitapiisini, Our Way of Life: How We Lived With Our
                  Families, During the Day”</a>, Glenbow Museum.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Hide', 'Brass', 'Beads', 'Sinew', 'Paint' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [ 'Siksika' ],
    },
    {
      title: 'Museum Collection',
      options: [ 'The British Museum' ],
    },
    {
      title: 'Theme',
      options: [ 'Pictographs', 'Clothing' ],
    },
  ],
}
