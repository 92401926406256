import React from 'react'
import Model from './Model/moccasins_MAA_1930.glb'
import Icon from './Model/moccasins_MAA_1930.png'
import PNG from './Model/moccasins_MAA_1930.webp'

export var moccasins_MAA_1930 = {
  id: 'moccasins_MAA_1930',
  name: 'Moccasins',
  translation: 'Niitsitsikiin',
  png: PNG,
  meta: {
    date: 'Before 1929',
    origin: 'Piikani',
    tribe: 'Piikani',
    materials: [ 'Beads', 'Trade Cloth', 'Hide' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'Museum of Archaeology and Anthropology',
      ref: 'https://collections.maa.cam.ac.uk/objects/487819/',
    },
    dimensions: {
      length: '18.5cm',
      width: '8.25cm',
      height: '10cm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    rti: '',
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Bead',
        category: 'materials',
        dataPosition:
          '1.7277959756802879m -1.1678941566213679m 2.482661428320422m',
        dataNormal:
          '-0.15158714217893335m 0.7712582810732347m 0.6182087044048987m',
        content: [
          {
            type: 'text',
            title: 'Bead',
            titleBlackfoot: 'Áótooksiinaattsi',
            description: (
              <div>
                <p>
                  Colonial expansion brought new materials with the settlers.
                  Among these items were beads. Beads were quickly recognized as
                  being valuable and were highly sought after by Indigenous
                  people when trade routes were established (Gray, 2017). The
                  seed bead was introduced in the 18th century and was smaller
                  than the pony beads, which arrived in the 16th century (as
                  cited in Gray, 2017). The beads used to decorate these
                  moccasins appear to be pony beads (round) and two cuts
                  (straight edges). The dark blue beads are sometimes called
                  ‘greasy beads’.
                  <br />
                  <br />
                  More than decoration, beadwork is an expression of the
                  beader’s identity, spiritual beliefs, and highlights the
                  connections to their kinship alliances. Designs can be passed
                  down through families and have different meanings and
                  teachings (Belcourt).
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  Belcourt, Christi. Beadwork: First Peoples' Beading History
                  and Techniques. Owen Sound, Ont: Ningwakwe Learning Press,
                  2010.
                </p>
                <p>
                  Gray, M. (2017). Beads: Symbols of Indigenous Cultural
                  Resilience and Value (Unpublished Master’s Thesis). University
                  of Toronto, Toronto, Ontario.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Hide',
        category: 'materials',
        dataPosition:
          '0.7091910976399372m 1.2500509934166457m -0.5847487966069977m',
        dataNormal:
          '-0.5942984905325438m 0.5806477246588236m 0.5564687987652777m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Hide is harvested from animals, which were mostly hunted for
                  food. Blackfoot people would try to use all that the animal
                  had to offer. This was done out of respect, as they knew that
                  the animal sacrificed itself.
                </p>
                <p>
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather is a lengthy tedious process that can span over a
                  length of time. This can range from days to weeks. Tanned
                  hides were central to survival, used for shelter, clothes,
                  bags, and more.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Trade Cloth',
        category: 'materials',
        dataPosition:
          '-1.1975574989538849m 2.0711427995176654m -2.886807518058m',
        dataNormal:
          '0.33543085930645833m 0.8688661462753657m -0.3640848231958125m',
        content: [
          {
            type: 'text',
            title: 'Cloth, textile fabric',
            titleBlackfoot: 'Nááipisstsi',
            description: (
              <div>
                <p>
                  Trade Cloth was first introduced to Native Americans during
                  the late 1700's. Trading posts were established across the
                  country and European textiles were traded for furs and animal
                  skins. The introduction of trade cloth was an important factor
                  in the decline of the lifestyle and culture of Native
                  Americans. Making clothes from buffalo hides or deerskin was a
                  long and difficult process. Trade Cloth became a highly
                  desirable commodity as it was much easier to cut and sew.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Prairie Crocus',
        category: 'design',
        dataPosition:
          '-1.5175549083135684m -0.9974975885986317m 2.4779399707635337m',
        dataNormal:
          '0.14556164349876424m 0.7619182451026262m 0.6311041084652146m',
        content: [
          {
            type: 'text',
            title: 'Prairie Crocus',
            titleBlackfoot: 'Ki’piaapi',
            description: (
              <div>
                <p>
                  The colorful floral pattern looks like the prairie crocus
                  flower, one of the first flowers to bloom in the spring. They
                  are also called “ears of the earth” because they listen for
                  the coming of spring (Nitsitapiisinni). While geometric
                  designs are usually associated with Blackfoot beadwork, floral
                  designs became common in the late 1800s, possibly influenced
                  by Cree. The symmetry of the floral pattern on these moccasins
                  is also common.
                </p>
              </div>
            ),
          },
          {
            type: 'text',
            title: 'Stories',
            description: (
              <div>
                <p>
                  <a
                    href="https://galileo.org/kainai/pasque-flower/ "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Crocus, by Marvin Calfrobe Jr (Nitsitapiisinni: Stories
                    and Spaces Exploring Kainai Plants and Culture).
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Moccasins were a part of daily wear for Blackfoot people. There
                are two styles of moccasins for the Blackfoot people. The older
                style which is made of one piece of soft hide that is sewn on
                one side, this is known as niitsitsikiin, the “real moccasin” in
                the Blackfoot language. Eventually, people transitioned to using
                a stiff rawhide sole stitched to a soft upper, which is how
                these moccasins were made (Blackfoot Crossing).
                <br />
                <br />
                Beaded moccasins are typically worn for special occasions. The
                designs provide spiritual protection for the wearer and indicate
                the families or tribes that they belonged to.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                <a
                  href="http://www.blackfootcrossing.ca/survival.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blackfoot Crossing. “Survival” (currently under construction).{' '}
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The moccasins came from Piikani, but the makers and wearers were
                not recorded. The floral pattern suggests that they may be from
                the mid 19th-century (Clifford Crane Bear, Glenbow Museum,
                1998).
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                The moccasins were purchased by Robert Rymill in August of 1929
                during his visit to the Piikani Nation. Rymill was a part of the
                Franklin Motor Expedition sent to Canada by Cambridge
                University’s Museum of Archeology and Anthropology (MAA) to
                collect traditional items from Indigenous people in the Canadian
                prairies. Over the course of their trip they purchased hundreds
                of items from Ojibwa, Cree, Tsuu T’ina, and Blackfoot
                communities. 199 of these were from Piikani, creating what is
                thought to be one of the largest known collections of Piikani
                artifacts (Brown, 2014).
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where are they now?',
          description: (
            <div>
              <p>
                At the end of the Franklin Motor Expidition’s journey across
                Canada and the US, the team shipped crates of the collection
                items to Cambridge University to become a part of the MAA. The
                MAA’s 1929 Annual Report notes “an extensive and valuable
                collection” made by R.R. Rymill for the museum (Brown, 2014).
                <br />
                <br />
                The moccasins continue to be housed in the MAA’s storage in
                Cambridge, England.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Brown, Alison K. First Nations, Museums, Narrations: Stories of
                the 1929 Franklin Motor Expedition to the Canadian Prairies. UBC
                Press. 2014.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Beads', 'Trade Cloth', 'Hide' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [ 'Piikani' ],
    },
    {
      title: 'Museum Collection',
      options: [ 'Museum of Archaeology and Anthropology' ],
    },
    {
      title: 'Theme',
      options: [ 'Clothing' ],
    },
  ],
}
