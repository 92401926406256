import React from 'react'
import Model from './Model/knife_BM_1856.glb'
import Icon from './Model/knife_BM_1856.png'
import PNG from './Model/knife_BM_1856.webp'

export var knife_BM_1856 = {
  id: 'knife_BM_1856',
  name: 'Knife',
  translation: 'Isttoán',
  png: PNG,
  meta: {
    date: 'Before 1856',
    origin: 'Sheffield UK & Niitsitapi',
    tribe: '',
    materials: ['Bone', "Steel", "Brass"],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The British Museum',
      ref: 'https://www.britishmuseum.org/collection/object/E_Am-2652_1',
    },
    dimensions: {
      length: '36.4cm',
      width: '',
      height: '6cm',
      additions: [{ depth: '1.9cm'}],
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Buffalo or Elk Bone',
        category: 'materials',
        dataPosition:
          '0.38242808795225613m -0.24476196324796184m -0.5087295976405275m',
        dataNormal:
          '0.5657647671441565m 0.8188335146376642m -0.09706649043026654m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  This handle is carved bone or horn attached with brass rivets.
                  Bone is an important part of animals that the Blackfoot used
                  for tools and weapons. 
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Blade',
        category: 'materials',
        dataPosition:
          '-0.17402702379838164m 0.18589662623815473m 0.29254168679268555m',
        dataNormal:
          '0.6280238940443164m 0.7768162580314838m -0.046287036709822045m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The steel blade is marked I&H Sorby, [John and Henry Sorby] a
                  small family company that was active from 1822 to 1855
                  producing shearing tools, saws and chisels; tools for managing
                  animals and wood, as well as clean, long, double-edged blades
                  like this knife. Blades like this were made in huge numbers
                  for export and trade, they were commissioned by trading
                  companies from small workshops in Sheffield in the North of
                  England, a town famous for its steel production.
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This knife is created from a Blackfoot-made bone or horn handle
                and a cast carbon steel blade from the UK. The blade would have
                been brought to North America by European traders. The metal
                workers mark on the blade suggests it was made in Sheffield, UK
                between 1822 and 1855. We don’t know when exactly the blade
                travelled or how many hands it passed through before Christy
                acquired it. We asked the Archivist of the Company of Cutlers of
                Hallamshire for their opinion and they suggested that perhaps an
                original handle had been broken and it had been replaced. They
                were intrigued by the crafting of the handle and wondered how
                comfortable it was to use. They also remarked that the maker’s
                mark shows little wear. The handle is made of two pieces of
                carved bone riveted with 8 brass rivets to the flat tang of the
                blade. There is a round paper sticker on the handle with the
                museum number 2652 handwritten in pen and ink. It also has a
                paper tag label which was removed for the recording.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          description: (
            <div>
              <p>
                This knife does not have a sheath associated with it in the
                museum collection. Similar knives were pictured worn around the
                neck in a scabbard or sheath of hide sometimes glamoured with
                dyed porcupine quills or glass beads.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The paper-tag museum label marks the knife as Plains, Blackfoot, but the name of its original owner was not recorded. 
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                The knife was most likely bought or ‘collected’ from Blackfoot
                territory by the banker and textile manufacturer Henry Christy
                on his trip across America, Canada and BC in 1856. Christy would
                then have kept it at his home as part of his extensive
                collection on Victoria Street in South West London. It might be
                that his Quaker religion made him interested to collect items of
                utility like this particular knife.
              </p>
              <p>
                Christy introduced machinery for weaving silk to his family’s
                manufactory, replacing the beaver pelts that had been used to
                cover top hats. This likely had an effect on the ecology of the
                beaver and the beaver people and also on the trade in beaver fur
                and the subsistence of the people who had come to rely upon it.
                Blades were often traded for furs and hafted locally.
              </p>
            </div>
          ),
        },
        {
          type: 'quote',
          quote: (
            <div>
              <p>
                The standard of exchange in all mercantile transactions... is a
                beaver skin... A coarse butcher’s knife is one skin.
              </p>
            </div>
          ),
          person: 'Sir John Richardson',
        },
        {
          type: 'text',
          description: (
            <div>
              <p>
                Christy was a Quaker and an abolitionist. He was a committee
                member of the{' '}
                <a
                  href="https://www.quakersintheworld.org/quakers-in-action/386"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Aborigines Protection Society
                </a>{' '}
                founded in 1837 with a social and activist agenda <em>‘to ensure the
                health and well-being and the sovereign, legal and religious
                rights of the indigenous peoples while also promoting the
                civilisation of the indigenous people who were subjected under
                colonial powers.’</em> 
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now? ',
          description: (
            <div>
              <p>
                After his death in 1865, Christy’s substantial collection was
                bequeathed to the British Museum. The knife’s current resting
                place is in the stores of the British Museum in London, England.
              </p>
              <p>
                3D printing would allow iterations of the model of the knife to
                be handled and touched so that people can get a sense of scale
                and the feel of it in your hand.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                <a href="https://archive.org/details/cihm_22598/page/n23/mode/2up" target="_blank"
                  rel="noopener noreferrer">Sir John Richardson, quoted by Aborigines Protection Society. "Canada West and the Hudson's-Bay
                Company: a political and humane question of vital importance to the honour of Great Britain, to the prosperity of Canada and to the existence of the native tribes", Pg 15/16. 1856.</a>
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: ['Bone', "Steel", "Brass"],
    },
    {
      title: 'Blackfoot Tribe',
      options: [],
    },
    {
      title: 'Museum Collection',
      options: ['The British Museum'],
    },
    {
      title: 'Theme',
      options: ['Tools', "Weapons"],
    },
  ],
}
