import React from 'react'
import Model from './Model/scraper_BM_1930.glb'
import Icon from './Model/scraper_BM_1930.png'
import PNG from './Model/scraper_BM_1930.webp'

import Map from './Model/content/Map.jpeg'
import Fleshing from './Model/content/cp18028v.jpg'

export var scraper_BM_1930 = {
  id: 'scraper_BM_1930',
  name: 'Hide Scraper',
  translation: 'Iihtáísatsínaakio’p',
  png: PNG,
  meta: {
    date: 'Unknown',
    origin: '',
    tribe: '',
    materials: [ 'Bone' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The British Museum',
      ref: 'https://www.britishmuseum.org/collection/object/E_Am1937-0315-93',
    },
    dimensions: {
      length: '27cm',
      width: '',
      height: '11cm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Bone',
        category: 'materials',
        dataPosition:
          '-1.519114234815323m -0.42790312647216344m 0.32723672578226576m',
        dataNormal:
          '0.007031146260886874m 0.21779089233450724m 0.9759701277182603m',
        content: [
          {
            type: 'text',
            title: 'Bone',
            translation: 'Okin',
            description: (
              <div>
                <p>
                  Blackfoot people would try to use all that the animal had to
                  offer. This was done out of respect, as they knew that the
                  animal sacrificed itself. Bones made good tools, and a big
                  bone like this one would be full of fat, which was used for
                  mookimaani (pemmican — dry meat, berries, and fat mixed
                  together).
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  <a
                    href="https://www.glenbow.org/blackfoot/EN/html/our_campsite.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    “Niitsitapiisini, Our Way of Life: How We Lived With the
                    Buffalo, Our Campsite” Glenbow Museum
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This hide scraper is part hide preparation, a skilled process
                done by talented Blackfoot women. Women often worked in groups
                to complete the time-consuming work. Once the hide is stretched
                and pegged, the scraper was used to remove fat and meat from one
                side and hair from the other (The Blackfoot Gallery Committee).
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Map',
          url: Fleshing,
          description: (
            <div>
              <p>
                A Siksika women fleshes a hide. Photo by Edward Curtis, 1926
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                <a
                  href="https://www.glenbow.org/blackfoot/EN/html/onto_the_prairies.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  “Niitsitapiisini, Our Way of Life: How We Lived With the Land,
                  Onto the Prairies” Glenbow Museum
                </a>
              </p>
              <p>
                Edward S. Curtis. "Fleshing a hide - Blackfoot". The North
                American Indian (1907-1930) v.18. 1928.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                This hide scraper was labelled as being found in Hilldown,
                Alberta, which is a hamlet east of Red Deer, Alberta. It could
                mean that it was collected before Treaty 7 moved the Blackfoot
                to reservations in 1877.
              </p>
              <p>
                Prior to European settlement, Blackfoot territory spanned from
                the Ponokasisahta (Elk River, now called the North Saskatchewan
                River) to Otahkoitahtayi (Yellowstone River) in Montana, and
                from the east slopes of the Rockies to Omahskispatsikoyii (the
                Great Sand Hills) in Saskatchewan (The Blackfoot Gallery
                Committee). To learn more about Treaty 7, visit{' '}
                <a
                  href="https://www.blackfootcrossing.ca/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blackfoot Crossing Historical Park
                </a>
                , where Treaty 7 was signed.
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Map',
          url: Map,
          description: (
            <div>
              <p>Map of blackfoot territory by Rick Lalonde, Glenbow Museum</p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquistion',
          description: (
            <div>
              <p>
                It is unknown when this hide scraper was collected or who
                collected it. It was found unnumbered in The British Museum
                stores with an old label “N.American Indian, found at Hillsdown,
                Alta. Skinscraper (satrinakiatsis).”
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                At some point, the hide scraper was donated to The British
                Museum where it initially was not catalogued. It now sits in the
                British Museum storage in London, England.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Bone' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [],
    },
    {
      title: 'Museum Collection',
      options: [ 'The British Museum' ],
    },
    {
      title: 'Theme',
      options: [ 'Tools' ],
    },
  ],
}
