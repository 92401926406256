import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style/Tooltip.module.css'
class Tooltip extends Component {
  componentDidMount() {
    if (
      this.props.name === undefined ||
      this.props.name === null ||
      this.props.name === ''
    ) {
      this.props.name = this.props.cacheName
    }
    //localStorage.removeItem(this.props.cacheName)
    this.getPointStyle()
    window.addEventListener('resize', this.getPointStyle)
    var tooltip = document.getElementById(this.props.name)

    tooltip.addEventListener('wheel', this.stopPropagation, { passive: true })
    tooltip.addEventListener('touchmove', this.stopPropagation, {
      passive: true,
    })
  }

  stopPropagation = (e) => {
    e.stopPropagation()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getPointStyle)
    var tooltip = document.getElementById(this.props.name)
    tooltip.removeEventListener('wheel', this.stopPropagation, {
      passive: true,
    })
    tooltip.removeEventListener('touchmove', this.stopPropagation, {
      passive: true,
    })
  }
  componentDidUpdate() {
    this.getPointStyle()
    if (this.props.cached === true && !this.isCached()) {
      localStorage.setItem(
        this.props.cacheName,
        JSON.stringify(this.props.cacheName),
      )
      // document.getElementById(this.props.cacheName).style.display = 'none'
      this.forceUpdate()
    }
  }
  display = () => {
    if (this.isCached() === true || this.props.display === false) {
      return { display: 'none' }
    } else {
      return { display: 'block' }
    }
  }

  getTooltipContainerStyle = () => {
    if (
      this.props.pointDirection === 'up' ||
      this.props.pointDirection === 'Up'
    ) {
      return [style.tooltipContainer, style.up].join(' ')
    } else if (
      this.props.pointDirection === 'right' ||
      this.props.pointDirection === 'Right'
    ) {
      return [style.tooltipContainer, style.right].join(' ')
    } else if (
      this.props.pointDirection === 'down' ||
      this.props.pointDirection === 'Down'
    ) {
      return [style.tooltipContainer, style.down].join(' ')
    } else if (
      this.props.pointDirection === 'left' ||
      this.props.pointDirection === 'Left'
    ) {
      return [style.tooltipContainer, style.left].join(' ')
    }
    // else if (
    //   this.props.pointDirection === 'none' ||
    //   this.props.pointDirection === 'None'
    // ) {
    //   return style.pointNone
    // } else {
    //   return style.pointNone
    // }
  }

  getTooltipInnerStyle = () => {
    if (
      this.props.pointDirection === 'up' ||
      this.props.pointDirection === 'Up'
    ) {
      return [style.tooltipInner, style.up].join(' ')
    } else if (
      this.props.pointDirection === 'right' ||
      this.props.pointDirection === 'Right'
    ) {
      return [style.tooltipInner, style.right].join(' ')
    } else if (
      this.props.pointDirection === 'down' ||
      this.props.pointDirection === 'Down'
    ) {
      return [style.tooltipInner, style.down].join(' ')
    } else if (
      this.props.pointDirection === 'left' ||
      this.props.pointDirection === 'Left'
    ) {
      return [style.tooltipInner, style.left].join(' ')
    }
    // else if (
    //   this.props.pointDirection === 'none' ||
    //   this.props.pointDirection === 'None'
    // ) {
    //   return style.pointNone
    // } else {
    //   return style.pointNone
    // }
  }

  getPointStyle = () => {
    var box = document.getElementById(this.props.name).getBoundingClientRect()
    var point = document.getElementById(`${this.props.name}_point`)
    if (
      this.props.pointDirection === 'up' ||
      this.props.pointDirection === 'Up'
    ) {
      point.style.borderLeft = `${Math.trunc(
        box.width / 2,
      )}px solid transparent`
      point.style.borderRight = `${Math.trunc(
        box.width / 2,
      )}px solid transparent`
    } else if (
      this.props.pointDirection === 'right' ||
      this.props.pointDirection === 'Right'
    ) {
      point.style.borderTop = `${Math.trunc(
        box.height / 2,
      )}px solid transparent`
      point.style.borderBottom = `${Math.trunc(
        box.height / 2,
      )}px solid transparent`
    } else if (
      this.props.pointDirection === 'down' ||
      this.props.pointDirection === 'Down'
    ) {
      point.style.borderLeft = `${Math.trunc(
        box.width / 10,
      )}px solid transparent`
      point.style.borderRight = `${Math.trunc(
        box.width / 10,
      )}px solid transparent`
    } else if (
      this.props.pointDirection === 'left' ||
      this.props.pointDirection === 'Left'
    ) {
      point.style.borderTop = `${Math.trunc(
        box.height / 2,
      )}px solid transparent`
      point.style.borderBottom = `${Math.trunc(
        box.height / 2,
      )}px solid transparent`
    }
  }
  isCached = () => {
    const cachedTooltip = localStorage.getItem(this.props.cacheName)
    if (cachedTooltip) {
      return true
    } else {
      return false
    }
  }

  pointDirection = () => {
    if (
      this.props.pointDirection === 'up' ||
      this.props.pointDirection === 'Up'
    ) {
      return style.pointUp
    } else if (
      this.props.pointDirection === 'right' ||
      this.props.pointDirection === 'Right'
    ) {
      return style.pointRight
    } else if (
      this.props.pointDirection === 'down' ||
      this.props.pointDirection === 'Down'
    ) {
      return style.pointDown
    } else if (
      this.props.pointDirection === 'left' ||
      this.props.pointDirection === 'Left'
    ) {
      return style.pointLeft
    } else if (
      this.props.pointDirection === 'none' ||
      this.props.pointDirection === 'None'
    ) {
      return style.pointNone
    } else {
      return style.pointNone
    }
  }

  render() {
    return (
      <div
        className={this.getTooltipContainerStyle()}
        style={this.display()}
        id={this.props.name}
      >
        <div
          className={this.pointDirection()}
          id={`${this.props.name}_point`}
        ></div>
        <div
          className={this.getTooltipInnerStyle()}
          style={this.props.innerStyle}
        >
          <p>{this.props.tooltipText}</p>
        </div>
      </div>
    )
  }
}

export default Tooltip

Tooltip.propTypes = {
  pointDirection: PropTypes.oneOf([
    'up',
    'Up',
    'right',
    'Right',
    'down',
    'Down',
    'left',
    'Left',
    'none',
    'None',
  ]),
  tooltipText: PropTypes.string.isRequired,
  // Non-unique string indicating the cache name. Shared with other elements you wish to group together for display
  cacheName: PropTypes.string.isRequired,
  //Unique identifier
  name: PropTypes.string.isRequired,
  cached: PropTypes.bool.isRequired,
  display: PropTypes.bool,
}
