import React from 'react'
import Model from './Model/sheath_BM_1887.glb'
import Icon from './Model/sheath_BM_1887.png'
import PNG from './Model/sheath_BM_1887.webp'

import Crowfoot from './Model/content/crowfoot-Campbell.png'

export var sheath_BM_1887 = {
  id: 'sheath_BM_1887',
  name: ' Knife Sheath',
  translation: 'Sottaon',
  png: PNG,
  meta: {
    date: 'Before 1887',
    origin: '',
    tribe: '',
    materials: [ 'Hide', 'Brass', 'Beads', 'Sinew' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'The British Museum',
      ref: 'https://www.britishmuseum.org/collection/object/E_Am1887-1208-14',
    },
    dimensions: {
      length: '',
      width: '25cm',
      height: '9cm ',
      additions: [ { depth: '2.75cm' } ],
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Beads',
        category: 'materials',
        dataPosition:
          '-0.38366312074005177m -1.1900640234218673m 1.517983789354001m',
        dataNormal:
          '0.06471414141364072m 0.8494823173351549m 0.5236333377813055m',
        content: [
          {
            type: 'text',
            title: 'Bead',
            titleBlackfoot: 'Áótooksiinaattsi',
            description: (
              <div>
                <p>
                  Colonial expansion brought new materials with the settlers.
                  Among these items were beads. Beads were quickly recognized as
                  being valuable and were highly sought after by Indigenous
                  people when trade routes were established (Gray, 2017). The
                  seed bead was introduced in the 18th century and was smaller
                  than the pony beads, which arrived in the 16th century(as
                  cited in Gray, 2017). The beads used to create this item were
                  manufactured and brought over by settlers as a means of trade
                  materials.
                  <br />
                  <br />
                  More than decoration, beadwork is an expression of the
                  beader’s identity, spiritual beliefs, and highlights the
                  connections to their kinship alliances. Designs can be passed
                  down through families and have different meanings and
                  teachings (Belcourt).
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  Belcourt, Christi. Beadwork: First Peoples' Beading History
                  and Techniques. Owen Sound, Ont: Ningwakwe Learning Press,
                  2010.
                </p>
                <p>
                  Gray, M. (2017). Beads: Symbols of Indigenous Cultural
                  Resilience and Value (Unpublished Master’s Thesis). University
                  of Toronto, Toronto, Ontario.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Tanned hide',
        category: 'materials',
        dataPosition:
          '0.25042494435577556m 0.3560413267974838m -0.6872157851358396m',
        dataNormal:
          '-0.07244967675154357m -0.7325773396719759m -0.676817173051724m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Hide is harvested from animals, which were mostly hunted for
                  food. Blackfoot people would try to use all that the animal
                  had to offer. This was done out of respect, as they knew that
                  the animal sacrificed itself.
                  <br />
                  <br />
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather is a lengthy tedious process that can span over a
                  length of time. This can range from days to weeks. Tanned
                  hides were central to survival, used for shelter, clothes,
                  bags, and more.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
        ],
      },
      // {
      //   name: 'Sinew',
      //   category: 'materials',
      //   dataPosition:
      //     '2.103277871334799m 10.956624874713352m -19.399018111921908m',
      //   dataNormal:
      //     '0.7660606356407726m 0.1231097652149686m 0.6308685189723534m',
      //   content: [
      //     {
      //       type: 'text',
      //       title: 'Neck Sinew',
      //       titleBlackfoot: 'Paakahtaan',
      //       description: (
      //         <div>
      //           <p>
      //             Sinew is the tough fibrous tissues that unite muscle to bone or bone to bone on the animal. It lies along the backbone of the animal, once the meat is scraped off you plaster the strip onto a flat service to create sinew (Hungrywolf, 1982, p. 240). This was often harvested from animals that existed within the region such as deer and buffalo and used to create items used for both ceremony and everyday use. Sinew had to be stored properly to prevent rotting. It is important to note that although commercial thread has been introduced to Indigenous populations, there are still individuals who harvest material and create sinew using traditional methods that have been transferred to them from their family members or kin. 
      //           </p>
      //         </div>
      //       ),
      //     },
      //     {
      //       type: 'references',
      //       references: (
      //         <div>
      //           <p>
      //             Hungry Wolf, Beverly. (1982). The ways of my grandmothers (1st Quill ed.). New York: Quill.
      //           </p>
      //         </div>
      //       ),
      //     },
      //   ],
      // },
      {
        name: 'Brass',
        category: 'materials',
        dataPosition:
          '0.6263932957176448m -0.5394292295555941m 0.7266037870532445m',
        dataNormal:
          '0.1527185136739058m 0.7543780648804203m 0.6384283756291742m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Brass beads and studs were a material introduced to the Blackfoot from trade with Europeans and other nations. The Blackfoot would often trade their products to gain useful materials and other finished products.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Morning Star',
        category: 'design',
        dataPosition:
          '0.5155921094493041m 1.7299614035160884m -1.7433728409109555m',
        dataNormal:
          '-0.09705272356553257m 0.8660442948280873m 0.49045697899431845m',
        content: [
          {
            type: 'text',
            title: 'Morning Star',
            titleBlackfoot: 'Ipiso waahsa',
            description: (
              <div>
                <p>
                  The bead pattern at the top of the case is of Ipiso waahsa
                  (the Morning Star) is the son of Natosi(Sun) and Kokomi kisomm
                  (Moon), the Spomi-tapi-ksi (The Sky Beings) who give Blackfoot
                  people important ceremonies and guidance.
                </p>
              </div>
            ),
          },
          {
            type: 'text',
            title: 'Stories',
            description: (
              <div>
                <p>
                  <a
                    href="https://www.blackfootdigitallibrary.com/digital/collection/bdl/id/226 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Star Woman as told by Alfreda Delaney A version of the
                    Sooa'tsaakii story as told by Alfreda Delaney and dramatized
                    by Norma Russell, Jenny Fox, Gordon Morning Bird, and Faye
                    Heavy Shield.
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.virtualmuseum.ca/edu/ViewLoitDa.do;jsessionid=EAF551A7FAFC4E248DC36B0D0072A2C7?method=preview&lang=EN&id=5220"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The North Star and Morning Star Another version of the
                    Sooa’tsaakii story as told by Mrs. Wolf Plume,
                    Amsskaapipikani in 1911.
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Mistaken Morning Star',
        category: 'design',
        dataPosition:
          '0.20539526038878786m 1.0501390767905971m -0.943843354677401m',
        dataNormal:
          '-0.17067903899019188m 0.7116712594820093m 0.6814636337154576m',
        content: [
          {
            type: 'text',
            title: 'Mistaken Morning Star',
            titleBlackfoot: 'Pa’piso waahsa',
            description: (
              <div>
                <p>
                  When there are two crosses, one is the morning star and the
                  other is the Mistaken Morning Star.
                </p>
              </div>
            ),
          },
          {
            type: 'text',
            title: 'Stories',
            description: (
              <div>
                <p>
                  <a
                    href="https://www.indigenouspeople.net/sweatlod.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Origin of the Sweat Lodge on indigenouspeople.net compiled
                    by Glenn Welker.
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Flower',
        category: 'design',
        dataPosition:
          '-0.2579874554833096m -0.20104848777627304m 0.37436721121835737m',
        dataNormal:
          '0.07028138633971458m 0.7024731905144632m 0.7082315605383585m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  While geometric designs are usually associated with Blackfoot beadwork, floral designs became common in the late 1800s, possibly influenced by Cree.
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This knife sheath is adorned with seed beads in an intricate
                design, using both geometrical and natural designs, with brass
                buttons and metal cones being incorporated into the overall
                design delivery of the object. This sheath could be worn on a
                belt or strung on a string to carry across the body. The level
                of detail applied to this object, for this time period,
                highlights the importance of the object it is meant to carry, as
                well as the individual it was made for.
                <br />
                <br />
                The beaded knife sheath is constructed of hides, and man-made
                materials that were most likely acquired through trade. The
                beaded designs depict mountains and wildflowers. You can also
                see the morning star and the mistaken morning star.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The sheath was made and used some time in the mid 1800s. The use
                of metal and brass mean that it was made after trade with
                Europeans brought new materials that would prove to be
                beneficial for everyday use.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                The sheath came to be in the possession of John Douglas Sutherland Campbell, the Governor General of Canada from 1873 - 1883. During his travels across Canada, he met with Chief Crowfoot at Blackfoot Crossing, along with other First Nations.</p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Crowfoot meets Campbell',
          url: Crowfoot,
          description: (
            <div>
              <p>
                A sketch of Chief Crowfoot addressing Campbell at Blackfoot Crossing pow wow on September 10, 1887, by Sydney Prior Hall.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          description: (
            <div>
              <p>
                In addition to the sheath, Campbell also acquired several Blackfoot headdresses, a shirt, sheild, and pipe. You can see the other items on the British Museum website, under <a href="https://www.britishmuseum.org/collection/term/BIOG131234" target="_blank"
                  rel="noopener noreferrer">'Related Objects'</a>
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                Since being purchased by The British Museum, the sheath has been on display in London, Manchester, and Exeter. It is currently housed in the British Museum storage in London, England.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Hide', 'Brass', 'Beads', 'Sinew' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [],
    },
    {
      title: 'Museum Collection',
      options: [ 'The British Museum' ],
    },
    {
      title: 'Theme',
      options: [ 'Cases' ],
    },
  ],
}
