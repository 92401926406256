import React from 'react'
import Model from './Model/pipebag_MAA_1929.glb'
import Icon from './Model/pipebag_MAA_1929.png'
import PNG from './Model/pipebag_MAA_1929.webp'

export var pipebag_MAA_1929 = {
  id: 'pipebag_MAA_1929',
  name: 'Pipe and Swan Foot Bag',
  translation: '',
  png: PNG,
  meta: {
    date: 'Before 1929',
    origin: 'Piikani',
    tribe: 'Piikani',
    materials: ['Wood', 'Stone', 'Hide'],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'Museum of Archaeology and Anthropology',
      ref: 'https://collections.maa.cam.ac.uk/objects/487862/',
    },
    dimensions: {
      length: '',
      width: '',
      height: '',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    rti: '',
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Swan Foot',
        category: 'materials',
        dataPosition:
          '-0.19745936835042777m -1.1733515540754027m -1.2908295799718772m',
        dataNormal:
          '0.1854021849104559m 0.034095047537172914m -0.9820710552520461m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The foot skin has been dyed with red ochre to give it the
                  vibrant red color. You can see two claws still attached. While
                  people from certain societies cannot eat birds, swans are the
                  only bird no one can eat (Danielle Heavy Head, in
                  conversation, 2020).
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Stone',
        category: 'materials',
        dataPosition:
          '2.7511617412646707m 4.105921067161752m 1.055186663868085m',
        dataNormal:
          '-0.32641437183321953m -0.04636745066942388m 0.9440888291782418m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>The pipe bowl is made of an unidentified brown stone.</p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Wood',
        category: 'materials',
        dataPosition:
          '0.503463547753972m 3.231971862057628m -0.2113781988595651m',
        dataNormal:
          '-0.12593392446420412m -0.18607117812551366m 0.974432226139934m',
        content: [
          {
            type: 'text',
            title: 'wood of a pipe stem',
            titleBlackfoot: 'Mii sti',
            description: (
              <div>
                <p>
                  This round pipe stem is typical of Blackfoot pipes, with a
                  smooth polish and no decoration. The red wood may have been
                  dyed with the same ochre as the bag.
                </p>
                <p>
                  The Blackfoot translation for the wood of the pipe is from
                  Rosalyn LaPier and Shirlee Crow Shoe's 2004 publication,
                  Blackfeet Vocabulary Terms for Items of Material Culture.
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  <a
                    href="http://www.blackfootcrossing.ca/celebration.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blackfoot Crossing, “Celebration”
                  </a>{' '}
                  (Currently under construction)
                </p>
                <p>
                  <a
                    href="https://hsapp.hs.umt.edu/employee-database/index.php/pubtools/serveFile/files/1489/Blackfeet_Terms_of_Material_Culture_--_SH.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Rosalyn LaPier and Shirlee Crow Shoe. Blackfeet Vocabulary
                    Terms for Items of Material Culture. Pg 39. Piegan
                    Institute, Montana. 2004
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This pipe stem and bowl were kept together in a bag made from a
                swan’s foot. The collector, Robert Rymill recorded that it
                belonged to a woman. Trumpeter swans live in northern Alberta
                and BC, and migrate through Blackfoot territory.
                <br />
                <br />
                Pipes are an important part of many sacred ceremonies that renew
                kinship relations and alliances of Blackfoot people.
              </p>
            </div>
          ),
        },
        {
          type: 'quote',
          quote: (
            <div>
              <p>
                The powers of natural and cosmic alliances are transferred by
                and through those who are carriers of the bundles and pipes
              </p>
            </div>
          ),
          person: 'Betty Bastien',
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Betty Bastien, Blackfoot Ways of Knowing: The Worldview of the
                Siksikaitsitapi. University of Calgary Press. 2004.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                The pipe and swan bag belonged to a woman from Piikani, possibly
                a Sun Dance woman.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                In August of 1929, Robert Rymill purchased these items during
                his visit to the Piikani Nation. Rymill was a part of the
                Franklin Motor Expedition sent to Canada by Cambridge
                University’s Museum of Archeology and Anthropology (MAA) to
                collect traditional items from Indigenous people in the Canadian
                prairies. Over the course of their trip they purchased hundreds
                of items from Ojibwa, Cree, Tsuu T’ina, and Blackfoot
                communities. 199 of these were from Piikani, creating what is
                thought to be one of the largest known collections of Piikani
                artifacts (Brown, 2014).
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where are they now?',
          description: (
            <div>
              <p>
                At the end of the Franklin Motor Expidition’s journey across
                Canada and the US, the team shipped crates of the collection
                items to Cambridge University to become a part of the MAA. The
                MAA’s 1929 Annual Report notes “an extensive and valuable
                collection” made by R.R. Rymill for the museum (Brown, 2014).
                <br />
                <br />
                The pipe and bag continue to be housed in the MAA’s storage in
                Cambridge, England.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Brown, Alison K. First Nations, Museums, Narrations: Stories of
                the 1929 Franklin Motor Expedition to the Canadian Prairies. UBC
                Press. 2014.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: ['Wood', 'Stone', 'Hide'],
    },
    {
      title: 'Blackfoot Tribe',
      options: ['Piikani'],
    },
    {
      title: 'Museum Collection',
      options: ['Museum of Archaeology and Anthropology'],
    },
    {
      title: 'Theme',
      options: ['Ceremonial'],
    },
  ],
}
