import React from 'react'
import Model from './Model/bag_HM_1909.glb'
import Icon from './Model/bag_HM_1909.png'
import PNG from './Model/bag_HM_1909.webp'

import BuffaloJump from './Model/content/BuffaloJump.jpg'
import LittlePlume from './Model/content/LittlePlume.jpg'

export var bag_HM_1909 = {
  id: 'bag_HM_1909',
  name: 'Bison Foetus Bag',
  translation: '',
  png: PNG,
  meta: {
    date: 'Before 1909',
    origin: 'Amskapipiikani',
    tribe: 'Amskapipiikani',
    materials: [ 'Hide', 'Thread' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: 'The Horniman Museum',
    dimensions: {
      length: '21cm',
      width: '6.5cm',
      height: '13.8cm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    rti: '',
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Buffalo Hide',
        category: 'materials',
        dataPosition:
          '0.20245143059937415m 0.0504575242692841m -0.43775750277769276m',
        dataNormal:
          '-0.13447257662940088m 0.5414495257788629m -0.8299093547903256m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Iinii (buffalo) were mostly hunted for food and Blackfoot
                  people would try to use all that the animal had to offer. This
                  was done out of respect, as they knew that the animal
                  sacrificed itself. Buffalo were a staple of the Blackfoot way
                  of life, providing food, clothing, and shelter, until settlers
                  decimated their population to near extinction in the late
                  1800s.
                  <br />
                  <br />
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather, from an animal, is a lengthy tedious process
                  that can span over a length of time. This can range from days
                  to weeks.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
          {
            type: 'video',
            url: 'https://www.youtube.com/embed/6LJfPMoGMAg',
            title: 'Iinnii Initiative: The Return of the Buffalo',
            embed: true,
            description: (
              <div>
                <p>
                  In 2016, 88 buffalo were reintroduced to Blackeet lands
                  through the Iinii Initiative.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Contents',
        category: 'materials',
        dataPosition:
          '0.13320774773159078m 0.2455198654620339m 0.06801025000588444m',
        dataNormal:
          '-0.12687839723829586m 0.9890952233882112m 0.07478309558226433m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  The Horniman Museum and Gardens recorded comments about the
                  contents of the bag during a research visit made by Alison
                  Brown, Anita Herle, Alan Pard and Charlene Wolfe on March 5,
                  2015:
                  <br />
                  <br />
                  “At one time this may have been used for bearberries
                  (Arctostaphylos alpina / Arctostaphylos rubra). The opening is
                  brittle, so it hasn't been opened for a long time. [Alan Pard]
                  then prods it and says way it grinds feels like sweet pine
                  (Abies Lasiocarpa). It has a crunch to it, which suggests it
                  may not be berries that are inside. It could also be tobacco,
                  which wouldn't hold its smell in the way sweet pine would.
                  There was some further discussion about the contents, which
                  could include herbs or kinnikinnik (a smoking mixture of herbs
                  including bearberry leaf and tobacco).”
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This bag is made from buffalo fetus hide and would have been
                used for storage. This is reflective of how Blackfoot people
                used all that the animal had to offer. Before their extinction
                due to european settlement, the buffalo were central to the
                Blackfoot way of life, providing shelter, shields, clothes and a
                balanced diet.
                <br />
                <br />
                If a pregnant buffalo was hunted or caught in pis-skaan (buffalo
                jumps), the Blackfoot would be sure to make use of her offering.
                Pis-skaan is a complicated and dangerous hunting technique used
                by the Blackfoot before horses were brought to the area. Buffalo
                would be driven to the steep cliffs and over the edge, killing
                hundreds at a time and providing resources for large communities
                (The Blackfoot Gallery Committee).
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Buffalo Jump',
          url: BuffaloJump,
          description: (
            <div>
              <p>
                Buffalo jump near Browning and Two Medicine River. [Information
                from Blackfeet elders, Blackfeet photo workshop, Browning
                Montana, 22 July 2014 with Anita Herle. Photo by Eric Horniman.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                This bag came from someone living near the Two Medicine River in
                Blackfeet Territory. As the plains buffalo population had
                collapsed by the 1880s, it is likely that the bag is from before
                this time.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                According to the Horniman Museum, this bag was collected from
                the Blackfeet in 1909 by John Eric Horniman, the grandson of
                Frederick Horniman (founder of The Horniman Museum and Gardens).
                Eric was part of the expedition with A.C. Haddon and Edward
                Curtis to visit the Blackfeet and document their culture. Haddon
                also acquired many items that were donated to the Museum of
                Archeology and Anthropology at Cambridge University, which are
                also featured on this site.
                <br />
                <br />
                This photo taken by Eric Horniman shows Yellow Kidney, Little
                Plume, and Chief Crow (identified by Blackfeet elders, Blackfeet
                photo workshop, Browning Montana, 22 July 2014 with Anita
                Herle).
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Little Plume',
          url: LittlePlume,
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                Eric Horniman donated items he collected from his visit to the
                Blackfeet to his family's museum in 1913. This bag is now kept
                in storage at The Horniman Museum and Gardens in Forest Hill,
                London, England.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Hide', 'Thread' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [ 'Amskapipiikani' ],
    },
    {
      title: 'Museum Collection',
      options: [ 'The Horniman Museum' ],
    },
    {
      title: 'Theme',
      options: [ 'Cases' ],
    },
  ],
}
