import React from 'react'
import Model from './Model/amulet_MAA_1929.glb'
import Icon from './Model/amulet_MAA_1929.png'
import PNG from './Model/amulet_MAA_1929.webp'

export var amulet_MAA_1929 = {
  id: 'amulet_MAA_1929',
  name: "Has a nothing face",
  translation: 'Piikstaan',
  png: PNG,
  meta: {
    date: 'Before 1929',
    origin: '',
    tribe: 'Piikani',
    materials: ['Beads', 'Sinew', 'Hide'],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'Museum of Archaeology and Anthropology',
      ref: 'https://collections.maa.cam.ac.uk/objects/487824/',
    },
    dimensions: {
      length: '',
      width: '',
      height: '',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Hide',
        category: 'materials',
        dataPosition:
          '-4.215574033049672m -10.000511030830852m -11.682992255669989m',
        dataNormal:
          '0.2928240840148166m 0.37878060247160145m 0.8779403801011403m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Hide is harvested from animals, which were mostly hunted for
                  food. Blackfoot people would try to use all that the animal
                  had to offer. This was done out of respect, as they knew that
                  the animal sacrificed itself.
                  <br />
                  <br />
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather is a lengthy tedious process that can span over a
                  length of time. This can range from days to weeks. Tanned
                  hides were central to survival, used for shelter, clothes,
                  bags, and more.
                </p>
                <p>
                In Blackfoot, hide may be referred to in a few different ways: <br/>
                <strong>Paanssin</strong> (smoked or tanned hide)<br/>
                <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on both sides) <br/>
                <strong>Motokis</strong> (Hide or skin - before its been processed)
                </p>
              </div>
            ),
          },

        ],
      },
      {
        name: 'Sinew',
        category: 'materials',
        dataPosition:
          '2.530284785173178m -10.544964742674047m -11.28663171154623m',
        dataNormal:
          '-0.15141719931178596m 0.20857903225199173m 0.9662130298528361m',
        content: [
        {
          type: "text",
          title: "Neck sinew",
          titleBlackfoot: "Paakahtaan",
          description: (
            <div><p>
               Sinew is the tough fibrous tissues that unite muscle to bone or bone to bone on the animal. It lies along the backbone of the animal, once the meat is scraped off you plaster the strip onto a flat service to create sinew (Hungrywolf, 1982, p. 240). This was often harvested from animals that existed within the region such as deer and buffalo and used to create items used for both ceremony and everyday use. Sinew had to be stored properly to prevent rotting. It is important to note that although commercial thread has been introduced to Indigenous populations, there are still individuals who harvest material and create sinew using traditional methods that have been transferred to them from their family members or kin. 
            </p></div>
          ),
        },
        {
          type: "references",
          references: (
              <div>
                <p>
                  Hungry Wolf, Beverly. (1982). The ways of my grandmothers (1st Quill ed.). New York: Quill.
                </p>
              </div>
            ),
        },
        ],
      },
      {
        name: 'Beads',
        category: 'materials',
        dataPosition:
          '-1.2665366396964841m -10.341302395401064m -11.15702454034835m',
        dataNormal:
          '0.07921555386560619m 0.9389670377518824m 0.3347623008064387m',
        content: [
          {
            type: 'text',
            title: 'Bead',
            titleBlackfoot: 'Áótooksiinaattsi',
            description: (
              <div>
                <p>
                  Colonial expansion brought new materials with the settlers.
                  Among these items were beads. Beads were quickly recognized as
                  being valuable and were highly sought after by Indigenous
                  people when trade routes were established (Gray, 2017). The
                  seed bead was introduced in the 18th century and was smaller
                  than the pony beads, which arrived in the 16th century(as
                  cited in Gray, 2017). The beads used to create this item were
                  manufactured and brought over by settlers as a means of trade
                  materials.
                  <br />
                  <br />
                  More than decoration, beadwork is an expression of the
                  beader’s identity, spiritual beliefs, and highlights the connections to their kinship alliances. Designs
                  can be passed down through families and have different
                  meanings and teachings (Belcourt).
                </p>
              </div>
            ),
          },
          {
          type: "references",
          references: (
              <div>
                <p>
                  Belcourt, Christi. Beadwork: First Peoples' Beading History and Techniques. Owen Sound, Ont: Ningwakwe Learning Press, 2010.</p>
                  <p>
                  Gray, M. (2017). Beads: Symbols of Indigenous Cultural Resilience and Value (Unpublished Master’s Thesis). University of Toronto, Toronto, Ontario.

                </p>
              </div>
            ),
        },
        ],
      },
      {
        name: 'Naamsskii',
        category: 'design',
        dataPosition:
          '-3.042636042291141m -9.670133321819652m -12.123419947655437m',
        dataNormal:
          '0.22495582669093822m 0.7852673634155641m 0.5768449046252944m',
        content: [
          {
            type: 'text',
            title: 'has a nothing face',
            titleBlackfoot: 'Naamsskii',
            description: (
              <div>
                <p>
                  This amulet was made to represent a naamsskii, which translates to “has a nothing face”. Naamsskii’s are made for males, and sspopii’s (turtle) are made for females. If you look closely you can see the coils of the tail beaded onto the leather. The colors and designs were specific to each child, so as they aged it was easier to identify the child from a distance.  
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Children are considered a gift from the creator and are honoured
                when they enter this world. Blackfoot women would create these
                amulets for their child or grandchild shortly after their birth
                and it was often seen dangling from a cradleboard as the
                infant’s first toy. It also served as a symbolic connection
                between mother and baby, and contained the dried umbilical cord
                secured neatly within a special pouch that was held within the
                amulet. It symbolized the spiritual protection upon children and
                continued to serve the child later in life, bringing good
                fortune and protecting from any harm.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                This amulet belonged to someone from Piikani, but the name of the owner was not recorded. 
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                In August of 1929, Robert Rymill purchased these items during
                his visit to the Piikani Nation. Rymill was a part of the
                Franklin Motor Expedition sent to Canada by Cambridge
                University’s Museum of Archeology and Anthropology (MAA) to
                collect traditional items from Indigenous people in the Canadian
                prairies. Over the course of their trip they purchased hundreds of items from Ojibwa, Cree, Tsuu T’ina, and Blackfoot
                communities. 199 of these were from Piikani, creating what is
                thought to be one of the largest known collections of Piikani
                artifacts (Brown, 2014).
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                At the end of the Franklin Motor Expidition’s journey across
                Canada and the US, the team shipped crates of the collection
                items to Cambridge University to become a part of the MAA. The
                MAA’s 1929 Annual Report notes “an extensive and valuable
                collection” made by R.R. Rymill for the museum (Brown, 2014).
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Brown, Alison K. First Nations, Museums, Narrations: Stories of
                the 1929 Franklin Motor Expedition to the Canadian Prairies. UBC
                Press. 2014.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: ['Beads', 'Sinew', 'Hide'],
    },
    {
      title: 'Blackfoot Tribe',
      options: ['Piikani'],
    },
    {
      title: 'Museum Collection',
      options: ['Museum of Archaeology and Anthropology'],
    },
    {
      title: 'Theme',
      options: ['Child Rearing', 'Spirituality'],
    },
  ],
}
