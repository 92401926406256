import React from 'react'
import Model from './Model/awlcase_MAA_1909.glb'
import Icon from './Model/awlcase_MAA_1909.png'
import PNG from './Model/awlcase_MAA_1909.webp'

import Cutsdifferent from './Model/content/P1985-50-688.jpg'

export var awlcase_MAA_1909 = {
  id: 'awlcase_MAA_1909',
  name: 'Awl Case',
  translation: '',
  png: PNG,
  meta: {
    date: 'Before 1909',
    origin: 'Little Plume',
    tribe: 'Amskapipiikani',
    materials: ['Beads', 'Hide'],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'Museum of Archaeology and Anthropology',
      ref: 'https://collections.maa.cam.ac.uk/objects/487694/ ',
    },
    dimensions: {
      length: '15cm',
      width: '',
      height: '2cm',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    rti: '',
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Beads',
        category: 'materials',
        dataPosition:
          '-0.14712741915304584m 3.157856732839999m -13.921941227987976m',
        dataNormal:
          '-0.7941100508995401m 0.5352669202765102m 0.2878863510450437m',
        content: [
          {
            type: 'text',
            title: 'Bead',
            titleBlackfoot: 'Áótooksiinaattsi',
            description: (
              <div>
                <p>
                  Colonial expansion brought new materials with the settlers.
                  Among these items were beads. Beads were quickly recognized as
                  being valuable and were highly sought after by Indigenous
                  people when trade routes were established (Gray, 2017). The
                  seed bead was introduced in the 18th century and was smaller
                  than the pony beads, which arrived in the 16th century(as
                  cited in Gray, 2017). The beads used to create this item were
                  manufactured and brought over by settlers as a means of trade
                  materials.
                  <br />
                  <br />
                  More than decoration, beadwork is an expression of the
                  beader’s identity, spiritual beliefs, and highlights the
                  connections to their kinship alliances. Designs can be passed
                  down through families and have different meanings and
                  teachings (Belcourt).
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  Belcourt, Christi. Beadwork: First Peoples' Beading History
                  and Techniques. Owen Sound, Ont: Ningwakwe Learning Press,
                  2010.
                </p>
                <p>
                  Gray, M. (2017). Beads: Symbols of Indigenous Cultural
                  Resilience and Value (Unpublished Master’s Thesis). University
                  of Toronto, Toronto, Ontario.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Hide',
        category: 'materials',
        dataPosition:
          '0.18335320278558886m 3.9333044666156773m -14.54545282642498m',
        dataNormal:
          '0.06014698183846757m 0.13278131743402366m 0.9893186859228973m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Hide is harvested from animals, which were mostly hunted for
                  food. Blackfoot people would try to use all that the animal
                  had to offer. This was done out of respect, as they knew that
                  the animal sacrificed itself.
                  <br />
                  <br />
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather is a lengthy tedious process that can span over a
                  length of time. This can range from days to weeks. Tanned
                  hides were central to survival, used for shelter, clothes,
                  bags, and more.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Morning Star',
        category: 'design',
        dataPosition:
          '-0.14238843571827253m 2.9309613908459213m -14.18309281952098m',
        dataNormal:
          '-0.8167812203207592m -0.2495387963794789m -0.5201911449004228m',
        content: [
          {
            type: 'text',
            title: 'Morning Star',
            titleBlackfoot: 'Ipiso waahsa',
            description: (
              <div>
                <p>
                  The bead pattern at the top of the case is of Ipiso waahsa
                  (the Morning Star) is the son of Natosi(Sun) and Kokomi kisomm
                  (Moon), the Spomi-tapi-ksi (The Sky Beings) who give Blackfoot
                  people important ceremonies and guidance.
                </p>
              </div>
            ),
          },
          {
            type: 'text',
            title: 'Stories',
            description: (
              <div>
                <p>
                  <a
                    href="https://www.blackfootdigitallibrary.com/digital/collection/bdl/id/226 "
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Star Woman as told by Alfreda Delaney A version of the
                    Sooa'tsaakii story as told by Alfreda Delaney and dramatized
                    by Norma Russell, Jenny Fox, Gordon Morning Bird, and Faye
                    Heavy Shield.
                  </a>
                </p>
                <p>
                  <a
                    href="http://www.virtualmuseum.ca/edu/ViewLoitDa.do;jsessionid=EAF551A7FAFC4E248DC36B0D0072A2C7?method=preview&lang=EN&id=5220"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The North Star and Morning Star Another version of the
                    Sooa’tsaakii story as told by Mrs. Wolf Plume,
                    Amsskaapipikani in 1911.
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Mistaken Morning Star',
        category: 'design',
        dataPosition:
          '-0.364232898603408m 1.805798958902122m -12.882875148343185m',
        dataNormal:
          '-0.7766828789374159m -0.4305714317508776m -0.45975205026784455m',
        content: [
          {
            type: 'text',
            title: 'Mistaken Morning Star',
            titleBlackfoot: 'Pa’piso waahsa',
            description: (
              <div>
                <p>
                  When there are two crosses, one is the morning star and the
                  other is the Mistaken Morning Star.
                </p>
              </div>
            ),
          },
          {
            type: 'text',
            title: 'Stories',
            description: (
              <div>
                <p>
                  <a
                    href="https://www.indigenouspeople.net/sweatlod.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Origin of the Sweat Lodge on indigenouspeople.net compiled
                    by Glenn Welker.
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                This beaded case was used to store mooksis (awl), an important
                tool traditionally used for sewing, including stitching and
                beadwork. The awl, originally made of bone, would be used to
                pierce the hide so that the end of the sinew could be sewn
                through the holes made by the awl (Belcourt).
              </p>
              <p>
                Blackfoot women wore their awls in cases that hung from their
                belts, so that they were easily accessible when needed. An awl
                is an important tool that was utilized for most daily
                activities. Today it is still used by women in the confederacy.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                Belcourt, Christi. Beadwork: First Peoples' Beading History and
                Techniques. Owen Sound, Ont: Ningwakwe Learning Press, 2010.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                This awl case is one of the items that is marked as belonging to
                the Amskapipiikani Chief Little Plume, but as awls were used and
                worn by women, it may be that it belonged to his last wife, Cuts
                Different. They lived along the Two Medicine River where some of
                their descendants continue to live today.
              </p>
            </div>
          ),
        },
        {
          type: 'image',
          title: 'Cuts Different',
          url: Cutsdifferent,
          description: (
            <div>
              <p>
                Cuts Different making pemmican, taken between 1939 and 1942 by
                Helen M. Post.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                The awl case was one of several items acquired from the
                Blackfeet by Alfred Cort Haddon (1855-1940), a Reader in
                Ethnology at the University of Cambridge. Haddon met Little
                Plume during an expedition with photographer Edward Curtis and
                English student, John Eric Horniman in the summer of 1909. The
                purpose of their trip was to research and document Indigenous
                cultural traditions before they were lost due to European
                settlement (Gidley, 1982).
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where is it now?',
          description: (
            <div>
              <p>
                Haddon’s collection of Blackfoot items, including the awl case,
                became a part of the Museum of Archeology (MAA) and Anthropology
                at Cambridge University, where it remains today. The MAA also
                holds a large collection of photographs taken during his visit
                to Blackfeet territory in 1909.
              </p>
            </div>
          ),
        },
        {
          type: 'references',
          references: (
            <div>
              <p>
                <a
                  href="https://www.academia.edu/36053666/A_C_Haddon_Joins_Edward_S_Curtis_An_English_Anthropologist_Among_the_Blackfeet_1909 "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gidley, Mick. A. C. Haddon Joins Edward S. Curtis: An English
                  Anthropologist among the Blackfeet, 1909. Montana. 1982.
                </a>
              </p>
              <p>
                Helen M. Post (1907-1979); "Cuts Different", Making Pemmican;
                ca. 1936-1941; Gelatin silver print; Amon Carter Museum of
                American Art, Fort Worth, Texas, Gift of Peter Modley;
                P1985.50.688. © Amon Carter Museum of American Art.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: ['Beads', 'Hide'],
    },
    {
      title: 'Blackfoot Tribe',
      options: ['Amskapipiikani'],
    },
    {
      title: 'Museum Collection',
      options: ['Museum of Archaeology and Anthropology'],
    },
    {
      title: 'Theme',
      options: ['Cases', 'Tools'],
    },
  ],
}
