export const defaultTheme = 'light'

export const Themes = {
  light: {
    primaryColor: '#2D3E4E',
    secondaryColor: '#FDFBF7',
    tertiaryColor: '#4A6682',
    quaternaryColor: '#E2EAF4',
    backgroundColor: '#2d3e4e',
    background:
      'radial-gradient(53.52% 53.52% at 50% 46.48%, #FFFFFF 37.98%, #EAE7DF 100%)',
    navBackground:
      'linear-gradient(90deg, #EAE7DF 3.28%, #EBE8E1 30.68%, #EBE8E1 68.17%, #ECE9E2 95.57%)',
    navSlideOpacity: 'opacity(1)',
  },
  dark: {
    primaryColor: '#FDFBF7',
    secondaryColor: '#2A3948',
    tertiaryColor: '#FFF',
    quaternaryColor: '#C5D6E8',
    backgroundColor: '#2d3e4e',
    background:
      'radial-gradient(83.61% 83.61% at 50% 50%, rgba(0, 0, 0, 0) 22.4%, #000000 100%), #2D3E4E',
    navBackground:
      'linear-gradient(90deg, rgba(0, 0, 0, 0) 3.28%, #000000 30.68%, rgba(0, 0, 0, 0.99) 68.17%, rgba(0, 0, 0, 0) 95.57%)',
    navSlideOpacity: '0.5',
  },
}
