import React from 'react'
import Model from './Model/bracelet_MAA_1929.glb'
import Icon from './Model/bracelet_MAA_1929.png'
import PNG from './Model/bracelet_MAA_1929.webp'

export var bracelet_MAA_1929 = {
  id: 'bracelet_MAA_1929',
  name: 'Quillwork',
  translation: 'Atonáán',
  png: PNG,
  meta: {
    date: 'Before 1929',
    origin: 'Wahpeton Dakota Nation',
    tribe: 'Dakota',
    materials: [ 'Trade Cloth', 'Hide', 'Quills', 'Sinew' ],
    location: {
      lat: null,
      lng: null,
    },
    museum: {
      val: 'Museum of Archaeology and Anthropology',
      ref: 'hhttps://collections.maa.cam.ac.uk/objects/487658/',
    },
    dimensions: {
      length: '',
      width: '',
      height: '',
    },
  },
  mapMarker: {
    lat: null,
    lng: null,
    icon: Icon,
  },
  Model: {
    mdl: Model,
    icon: Icon,
    hotspots: [
      {
        name: 'Quill',
        category: 'materials',
        dataPosition:
          '2.6035478227575126m 1.2535291570548845m 2.0432319293820367m',
        dataNormal:
          '0.11099187461441094m 0.11629656367462018m 0.9869933703156542m',
        content: [
          {
            type: 'text',
            title: 'Quill',
            titleBlackfoot: 'Kaayíís',
            description: (
              <div>
                <p>
                  The western porcupine is native to Blackfoot Territory and can
                  still be seen today in some places. The tail and most of their
                  body is covered with hollow, cylindrical, quills that are
                  whitish in color with black tips. The average porcupine yields
                  about 8 ounces of quills, the quills are light in weight and
                  average about 4,000 quills to the ounce.
                  <br />
                  <br />
                  Quills were used in their natural color or dyed in one of a
                  few different ways: with vegetable dye or aniline dyes from
                  traders, or by boiling pieces of colored blanket with the
                  quills. Early vegetable colors were delicate reds, yellows,
                  blues, and greens. Yellow dye was made from the pine tree
                  lichen, Evernia vulpina. The quills were prepared by breaking
                  off the basal tip. Then they were dyed “by dampening a
                  quantity of the dye plant yielding the desired color in water,
                  placing a number of quills on top of it, wrapping them
                  together in a piece of buckskin, and placing the package under
                  their bed. After the package was pressed by the weight of the
                  woman’s body for a couple of nights it was unwrapped and the
                  dyed quills removed.”
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  <a
                    href="https://books.google.ca/books?id=QNqaDwAAQBAJ&amp;pg=PT183&amp;lpg=PT183&amp;dq=blackfoot+dye+quills&amp;source=bl&amp;ots=tw2w2mGKGw&amp;sig=ACfU3U1n16xLfpu9NG3umaP0vdRhz563Hg&amp;hl=en&amp;sa=X&amp;ved=2ahUKEwjgo8OH2cTmAhW-GDQIHSxNAJgQ6AEwC3oECAgQAQ#v=onepage&amp;q=blackfoot%20dye%20quills&amp;f=false"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tidwell Broughton, Deanna. Hide, Wood, and Willow: Cradles
                    of the Great Plains Indians. University Oklahoma Press.
                    2019.
                  </a>
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Tanned hide',
        category: 'materials',
        dataPosition:
          '2.8033646496624294m 1.7464904011550129m -2.2454946530031643m',
        dataNormal:
          '-0.1821871810266427m 0.20566268639473928m 0.9615147895349632m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  Hide is harvested from animals, which were mostly hunted for
                  food. Blackfoot people would try to use all that the animal
                  had to offer. This was done out of respect, as they knew that
                  the animal sacrificed itself.
                  <br />
                  <br />
                  Harvesting hide and applying tanning methods to create buttery
                  soft leather is a lengthy tedious process that can span over a
                  length of time. This can range from days to weeks. Tanned
                  hides were central to survival, used for shelter, clothes,
                  bags, and more.
                </p>
                <p>
                  In Blackfoot, hide may be referred to in a few different ways:{' '}
                  <br />
                  <strong>Paanssin</strong> (smoked or tanned hide)
                  <br />
                  <strong>Ohpaaninnimaa</strong> (oil prepared hide by hand on
                  both sides) <br />
                  <strong>Motokis</strong> (Hide or skin - before its been
                  processed)
                  <br />
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Sinew',
        category: 'materials',
        dataPosition:
          '-3.9997492815888247m -0.6014747929467601m -2.103250883756833m',
        dataNormal:
          '0.39467731779811116m 0.14185591490904734m 0.9078032354100785m',
        content: [
          {
            type: 'text',
            title: 'Neck Sinew',
            titleBlackfoot: 'Paakahtaan',
            description: (
              <div>
                <p>
                  Sinew is the tough fibrous tissues that unite muscle to bone
                  or bone to bone on the animal. It lies along the backbone of
                  the animal, once the meat is scraped off you plaster the strip
                  onto a flat service to create sinew (Hungrywolf, 1982, p.
                  240). This was often harvested from animals that existed
                  within the region such as deer and buffalo and used to create
                  items used for both ceremony and everyday use. Sinew had to be
                  stored properly to prevent rotting. It is important to note
                  that although commercial thread has been introduced to
                  Indigenous populations, there are still individuals who
                  harvest material and create sinew using traditional methods
                  that have been transferred to them from their family members
                  or kin.
                </p>
              </div>
            ),
          },
          {
            type: 'references',
            references: (
              <div>
                <p>
                  Hungry Wolf, Beverly. (1982). The ways of my grandmothers (1st
                  Quill ed.). New York: Quill.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Trade Cloth',
        category: 'materials',
        dataPosition:
          '2.7604883267780673m 2.499514118723585m 1.8900202673636861m',
        dataNormal:
          '0.21437803038342806m 0.3426320334372944m 0.9146831963863482m',
        content: [
          {
            type: 'text',
            title: 'Cloth, textile fabric',
            titleBlackfoot: 'Nááipisstsi',
            description: (
              <div>
                <p>
                  Trade Cloth was first introduced to Native Americans during
                  the late 1700's. Trading posts were established across the
                  country and European textiles were traded for furs and animal
                  skins. The introduction of trade cloth was an important factor
                  in the decline of the lifestyle and culture of Native
                  Americans. Making clothes from buffalo hides or deerskin was a
                  long and difficult process. Trade Cloth became a highly
                  desirable commodity as it was much easier to cut and sew.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        name: 'Plaiting',
        category: 'design',
        dataPosition:
          '-4.249539176193894m -0.3278558086397729m -2.184355660869193m',
        dataNormal:
          '-0.4145495582239516m -0.030102117835252722m -0.9095287385663849m',
        content: [
          {
            type: 'text',
            description: (
              <div>
                <p>
                  This type of quillwork is called “plaiting” which means two
                  quill elements cross each other obliquely, under one and over
                  one, forming a diamond shape.
                </p>
              </div>
            ),
          },
        ],
      },
    ],
  },
  description: [
    {
      title: 'Intro',
      content: [
        {
          type: 'text',
          description: (
            <div>
              <p>
                Pair of hide sleeve ornaments decorated with interlocking
                quillwork dyed orange and yellow displaying diamond-shaped
                patterns. The edges are trimmed with red cloth, and the green,
                blue, red and white ribbon tiers are now partially frayed.
                <br />
                <br />
                Quillwork is considered to be a sacred craft that is transferred
                from an older relative to a younger individual. Younger women
                were initiated ceremonially into the art of handling the
                sharp-pointed quills. They were taught how to use the tools of
                the quillworker’s craft and how to employ the various methods of
                quill-sewing, plaiting and wrapping. It is common knowledge
                within the Blackfoot Confederacy that individuals have to be
                given the rights or transferred the right to do quillwork.
                <br />
                <br />
                Blackfoot oral history explains how the method of quillworking
                was gifted to the Blackfoot people by Thunder, a spirit being.
                Quill work and the art of quilling was replaced by beads when
                they were introduced to tribes within North America. The beads
                were readily available and were easier to acquire due to the
                reserve systems being introduced into Canada.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'History',
      content: [
        {
          type: 'text',
          title: 'Origins',
          description: (
            <div>
              <p>
                According to museum records, these cuffs came from the Wahpeton
                Dakota Nation outside of Prince Albert Saskatchewan. The name of
                who made or wore them was unfortunately not recorded.
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Acquisition',
          description: (
            <div>
              <p>
                In August 1929, Donald Cadzow visited the Wahpeton Dakota Nation
                and purchased 38 items, including these cuffs. Cadzow was the
                leader of the Franklin Motor Expedition sent to Canada by
                Cambridge University’s Museum of Archeology and Anthropology
                (MAA) to collect traditional items from Indigenous people in the
                Canadian prairies. Over the course of their trip they purchased
                hundreds of items from Ojibwa, Dakota, Cree, Tsuu T’ina, and
                Blackfoot communities. (Brown, 2014).
              </p>
            </div>
          ),
        },
        {
          type: 'text',
          title: 'Where are they now?',
          description: (
            <div>
              <p>
                At the end of the Franklin Motor Expidition’s journey across
                Canada and the US, the team shipped crates of the collection
                items to Cambridge University to become a part of the MAA. The
                MAA’s 1929 Annual Report notes “an extensive and valuable
                collection” made by R.R. Rymill for the museum (Brown, 2014).
                <br />
                <br />
                The porcupine quilled cuffs are housed in the MAA’s storage in
                Cambridge, England.
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Materials',
      content: [],
    },
    {
      title: 'Design',
      content: [],
    },
  ],
  categories: [
    {
      title: 'Materials',
      options: [ 'Trade Cloth', 'Hide', 'Quills', 'Sinew' ],
    },
    {
      title: 'Blackfoot Tribe',
      options: [ 'Dakota' ],
    },
    {
      title: 'Museum Collection',
      options: [ 'Museum of Archaeology and Anthropology' ],
    },
    {
      title: 'Theme',
      options: [ 'Clothing' ],
    },
  ],
}
